import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import { apiUrls, deleteRequest, get, patch, post, put } from "utils/request";
import { ObjectWrapper, DATASOURCE_VALUE } from "utils/helper";

var _ = require("lodash");
const serviceName = "confCrusher";

export const initialState = {
  confCrusher: {
    loading: false,
    records: [],
    recordsTotal: "",
    error: "",
  },
  confCrusherMaster: {
    loading: false,
    records: [],
    recordsTotal: "",
    error: "",
  },
  confCrusherDetail: {
    loading: false,
    records: {
      crusherPlantList: [],
      statusLogList: [{ dataStatus: "DRAFT" }],
      documentList: [],
      createdAt: "",
    },
    recordsTotal: "",
    error: "",
  },
  confCrusherLatest: {
    loading: false,
    records: [],
    error: '',
  },
};

export const getconfCrusherData = createAsyncThunk(
  "confCrusher/get",
  async (params) => {
    const response = await post(apiUrls.confCrusher.concat(`filter`), {
      columns: [
        {
          data: "reportId",
          orderable: true,
          search: {
            regex: false,
            value: "",
          },
          searchValue: "",
          searchable: true,
        },
        {
          data: "bibNotes",
          orderable: true,
          search: {
            regex: false,
            value: "",
          },
          searchValue: "",
          searchable: true,
        },
        {
          data: "dataStatus",
          orderable: true,
          search: {
            regex: params?.dataStatus === DATASOURCE_VALUE.waitingApproval || params?.dataStatus >= 20 ? true : false,
            value: '',
          },
          searchValue: params.dataStatus,
          searchable: true,
        },
        {
          data: "createdAt",
          orderable: true,
          search: {
            regex: true,
            value: "",
          },
          searchValue: params.date,
          searchable: true,
        },
      ],
      order: [],
      search: {
        regex: false,
        value: params.query || "",
      },
      draw: params?.pageIndex ? params.pageIndex : 1,
      length: params?.dataLength ? params.dataLength : -1,
      start: 0,
    });
    return response?.data;
  }
);

export const getDetailService = createAsyncThunk(
  `${serviceName}/detail`,
  async (params) => {
    const { id } = params;
    const response = await get(`${apiUrls.confCrusher}${id}`);
    return response?.data;
  }
);

export const getLatestCrusherConfig = createAsyncThunk(
  `${serviceName}/latest-crusher-config`,
  async (params) => {
    const response = await get(`${apiUrls.confCrusher}/crusher-plant`);
    return response?.data;
  }
);

export const addService = createAsyncThunk(
  `${serviceName}/add`,
  async (param) => {
    const { data, username } = param;
    const response = await post(
      `${apiUrls.confCrusher}save?userName=${username}`,
      data
    );
    return response.data;
  }
);

export const saveService = createAsyncThunk(
  `${serviceName}/save`,
  async (param) => {
    const { data, id, username } = param;
    const response = await put(
      `${apiUrls.confCrusher}${id}/save?userName=${username}`,
      data
    );
    return response.data;
  }
);

export const submitService = createAsyncThunk(
  `${serviceName}/submit`,
  async (param) => {
    const { data, id, username } = param;
    const response = await put(
      `${apiUrls.confCrusher}${id}/submit?userName=${username}`,
      data
    );
    return response.data;
  }
);

//tambah

export const uploadFileService = createAsyncThunk(
  `${serviceName}/uploadFile`,
  async (param) => {
    const { id, file } = param;
    const response = await post(`${apiUrls.confCrusher}/${id}/files`, file, {
      headers: { "Content-Type": undefined },
    });
    return response.data;
  }
);

export const deleteFileService = createAsyncThunk(
  `${serviceName}/deleteFile`,
  async (param) => {
    const { id, name } = param;
    const response = await deleteRequest(
      `${apiUrls.confCrusher}${id}/files/${name}`
    );
    return response.data;
  }
);

export const updateDataService = createAsyncThunk(
  `${serviceName}/adminUpdate`,
  async (param) => {
    const { id, action, username, data } = param;
    const response = await put(
      `${apiUrls.confCrusher}${id}/${action}?userName=${username}`,
      data
    );
    return response.data;
  }
);

export const getconfCrusherMaster = createAsyncThunk(
  `${serviceName}/master`,
  async (params) => {
    const response = await post(apiUrls.mdPortCrusher.concat(`/filter`), {
      draw: 1,
      length: 50,
      start: 0,
    });
    var filtered = response.data.list.map((v) => {
      return {
        crusher: v,
        bib: {
          eu: 0,
          ma: 0,
          pa: 0,
          ua: 0,
        },
        productivityTarget: 0,
        kepmen1827: {
          eu: 0,
          ma: 0,
          pa: 0,
          ua: 0,
        },
      };
    });
    response.data.list = filtered;
    return response?.data;
  }
);

export const intervensiConfCrusher = createAsyncThunk(
  `${serviceName}/intervensi`,
  async (params) => {
    let response

    try {
      response = await post(`${apiUrls.intervensiConfigPort}/user-role?moduleName=Crushing`, params);
      return response?.data;
    }
    catch (err) {
      return err
    }
  }
);

const confCrusher = createSlice({
  name: "CONF_CRUSHER",
  initialState,
  reducers: {
    onChangeTableInput: (state, action) => {
      const payload = action.payload;
      _.set(
        state.confCrusherDetail.records.crusherPlantList[payload.tableIndexRow],
        payload.tableRowName,
        parseInt(payload.value)
      )
      _.set(
        state.confCrusherMaster.records[payload.tableIndexRow],
        payload.tableRowName,
        parseInt(payload.value)
      );
    },
    onChangeTableInputDetailConfCrusher: (state, action) => {
      const payload = action.payload;
      _.set(
        state.confCrusherDetail.records.crusherPlantList[payload.tableIndexRow],
        payload.tableRowName,
        parseInt(payload.value)
      );
    },
    onReset: (state, action) => {
      state.confCrusherDetail = {
        loading: false,
        records: {
          crusherPlantList: [],
          statusLogList: [{ dataStatus: "DRAFT" }],
          documentList: [],
          createdAt: "",
        },
        recordsTotal: "",
        error: "",
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getconfCrusherData.pending, (state, action) => {
      state.confCrusher.loading = true;
    });
    builder.addCase(getconfCrusherData.fulfilled, (state, action) => {
      state.confCrusher.records = action.payload.list;
      state.confCrusher.recordsTotal = action.payload.recordsTotal;
      state.confCrusher.loading = false;
    });
    builder.addCase(getconfCrusherData.rejected, (state, action) => {
      state.confCrusher.loading = false;
      state.confCrusher.error = "Invalid get data";
    });
    builder.addCase(getconfCrusherMaster.pending, (state, action) => {
      state.confCrusherMaster.loading = true;
    });
    builder.addCase(getconfCrusherMaster.fulfilled, (state, action) => {
      state.confCrusherMaster.records = action.payload.list;
      state.confCrusherMaster.recordsTotal = action.payload.recordsTotal;
    });
    builder.addCase(getconfCrusherMaster.rejected, (state, action) => {
      state.confCrusherMaster.loading = false;
      state.confCrusherMaster.error = "Invalid get data";
    });
    builder.addCase(getDetailService.pending, (state, action) => {
      state.confCrusherDetail.loading = true;
    });
    builder.addCase(getDetailService.fulfilled, (state, action) => {
      if (!state.confCrusherDetail.records?.id) {
        state.confCrusherDetail.records = action.payload;
      } else {
        state.confCrusherDetail.records = {
          ...action.payload,
          crusherPlantList: state.confCrusherDetail.records.crusherPlantList,
        };
      }
    });
    builder.addCase(getDetailService.rejected, (state, action) => {
      state.confCrusherDetail.loading = false;
      state.confCrusherDetail.error = "Invalid get data";
    });
    builder.addCase(getLatestCrusherConfig.pending, (state, action) => {
      state.confCrusherLatest.loading = true;
    });
    builder.addCase(getLatestCrusherConfig.fulfilled, (state, action) => {
      state.confCrusherLatest.loading = false;
      state.confCrusherLatest.records = action.payload;
    });
    builder.addCase(getLatestCrusherConfig.rejected, (state, action) => {
      state.confCrusherLatest.loading = false;
      state.confCrusherLatest.error = 'Invalid get data';
    });
  },
});
export const {
  onChangeTableInput,
  onChangeTableInputDetailConfCrusher,
  onReset,
} = confCrusher.actions;
export const confCrusherDataSelector = (state) => state.ConfCrusher.confCrusher;
export const confCrusherDetailSelector = (state) =>
  state.ConfCrusher.confCrusherDetail;
export const confCrusherMaster = (state) => state.ConfCrusher.confCrusherMaster;
export const confCrusherLatest = (state) => state.ConfCrusher.confCrusherLatest;

export default confCrusher.reducer;
