import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit';
import moment from 'moment';
import { getUsername } from 'utils/helper';
import { apiUrls, deleteRequest, get, post } from 'utils/request';
var _ = require('lodash');

export const initialState = {
  user: {
    loading: false,
    records: [],
    revert: [],
    onRevert: false,
    error: "",
    recordsTotal: "",
    statusLogList: [],
    statusLogListTotal: 0,
  },
  userMemberCrushing: {
    loading: false,
    error: "",
    records: [],
    recordsTotal: 0
  },
  userMemberBlc: {
    loading: false,
    error: "",
    records: [],
    recordsTotal: 0
  },
  userMemberJetty: {
    loading: false,
    error: "",
    records: [],
    recordsTotal: 0
  },
  userMemberRom: {
    loading: false,
    error: "",
    records: [],
    recordsTotal: 0
  },
  userDetail: {
    loading: false,
    error: "",
    detail: {}
  },
  userRole: [],
  actionRoleViewer: [],
  actionRoleCreator: [],
  actionRoleApprover: [],
  actionRoleCustom: [],
  actionRoleAdminBIB: [],
  actionRoleAdminContractor: [],
  actionRoleAdminMobile: [],
  actionRoleSuperAdmin: [],
}

export const getUserConfigurationList = createAsyncThunk('users', async (params) => {
  const response = await post(apiUrls.userConfig.concat(`/filter`), {
    "columns": [
      {
        "data": "id",
        "orderable": true,
        "search": {
          "regex": false,
          "value": ""
        },
        "searchValue": params?.id ?? "",
        "searchable": true
      },
      {
        "data": "email",
        "orderable": true,
        "search": {
          "regex": false,
          "value": ""
        },
        "searchValue": params?.email ?? "",
        "searchable": true
      },
      {
        "data": "userName",
        "orderable": true,
        "search": {
          "regex": false,
          "value": ""
        },
        "searchValue": params?.userName ?? "",
        "searchable": true
      },
      {
        "data": "company",
        "orderable": false,
        "search": {
          "regex": false,
          "value": ""
        },
        "searchValue": params?.company ?? "",
        "searchable": true
      },
      {
        "data": "role.name",
        "orderable": false,
        "search": {
          "regex": params?.role_regex ?? false,
          "value": ""
        },
        "searchValue": params?.role ?? "",
        "searchable": true
      },
      {
        "data": "entityStatus",
        "orderable": false,
        "search": {
          "regex": false,
          "value": ""
        },
        "searchValue": params?.status ?? "",
        "searchable": true
      }
    ],
    "order": params?.order ?? [
    ],
    "search": {
      "regex": true,
      "value": params.query || ""
    },
    "draw": params?.page ? params.page : 1,
    "length": params?.length ? params.length : -1,
  });
  return response?.data;
});

export const getUserConfigurationLogs = createAsyncThunk('users/logs',
  async (params) => {
    const response = await post(`${apiUrls.userConfig}/logs`,
      {
        "draw": params?.pageIndex ? params.pageIndex : 1,
        "columns": [
          {
            "data": "id",
            "name": "",
            "searchable": true,
            "orderable": true,
            "search": {
              "value": "",
              "regex": false
            }
          }
        ],
        "order": [
          {
            "column": 0,
            "dir": "desc"
          }
        ],
        "start": 0,
        "length": params?.recordLength ? params.recordLength : 10,
        "search": {
          "value": "",
          "regex": false
        }
      });
    return response?.data;
  }
);

export const getUserMemberFilter = createAsyncThunk('users-member-filter', async (params) => {
  const response = await post(`${apiUrls.userMember}user-members/filter`, {
    "columns": [
      {
        "data": "name",
        "orderable": true,
        "search": {
          "regex": false,
          "value": ""
        },
        "searchValue": "",
        "searchable": true
      },
      {
        "data": "company",
        "orderable": true,
        "search": {
          "regex": false,
          "value": ""
        },
        "searchValue": "",
        "searchable": true
      },
      {
        "data": "entityName",
        "orderable": true,
        "search": {
          "regex": false,
          "value": ""
        },
        "searchValue": params?.entityName || "",
        "searchable": true
      },
    ],
    "search": {
      "regex": true,
      "value": params?.query || ""
    },
    "draw": params?.pageIndex ? params.pageIndex : 1,
    "length": params?.recordLength ? params.recordLength : -1,
  });

  return response?.data;
});

export const getAttendanceAll = createAsyncThunk('attendance-all', async (params) => {
  const response = await post(`${apiUrls.attendances}/all`, {
    "columns": [
      {
        "data": "name",
        "orderable": true,
        "search": {
          "regex": true,
          "value": ""
        },
        "searchValue": params?.query || "",
        "searchable": true
      },
      {
        "data": "entityName",
        "orderable": true,
        "search": {
          "regex": false,
          "value": ""
        },
        "searchValue": params?.entityName || "",
        "searchable": true
      }
    ],
    "order": [],
    "search": {
      "regex": true,
      "value": ""
    },
    "draw": params?.pageIndex ? params.pageIndex : 1,
    "length": params?.recordLength ? params.recordLength : -1,
  });

  return response?.data;
});

export const getUserMember = createAsyncThunk('users/member',
  async () => {
    const response = await get(`${apiUrls.userMember}user-members`);
    return response?.data;
  }
);

export const submitUserMember = createAsyncThunk('users/submit-member',
  async (params) => {
    let response
    try {
      response = await post(`${apiUrls.userMember}user-members/save?userName=${getUsername()}`, params);
      return response
    } catch (error) {
      return error
    }
  }
);

export const deleteUserMember = createAsyncThunk('users/delete-member',
  async (params) => {
    let response
    try {
      response = await deleteRequest(`${apiUrls.userMember}user-members`, params);
      return response
    } catch (error) {
      return error
    }
  }
);

export const getDetailUser = createAsyncThunk(
  `users/detail`,
  async (params) => {
    const { id } = params;
    const response = await get(`${apiUrls.userConfig}/user-management/${id}`);
    return response?.data;
  }
);

export const getActionRole = createAsyncThunk(
  `users/action-role`,
  async (params) => {
    const { role } = params;
    const response = await get(`${apiUrls.userMember}/action-role?roleCode=${role}`);
    return response?.data;
  }
);

export const getActionRoleList = createAsyncThunk(
  `users/action-role-list`,
  async (params) => {
    const { role } = params;
    const response = await get(`${apiUrls.userMember}/action-role?roleCode=${role}`);
    return response?.data;
  }
);

export const submitUserDetail = createAsyncThunk('users/submit',
  async (params) => {
    let response
    try {
      response = await post(`${apiUrls.userConfig}/save?userName=${getUsername()}`, params);
      return response
    } catch (error) {
      return error
    }
  }
);

export const getAttendanceRomAll = createAsyncThunk('rom/attendance-all', async (params) => {
  const response = await post(`${apiUrls.attendanceRom}/all`, {
    "columns": [
      {
        "data": "name",
        "orderable": true,
        "search": {
          "regex": true,
          "value": ""
        },
        "searchValue": params?.query || "",
        "searchable": true
      },
      {
        "data": "entityName",
        "orderable": true,
        "search": {
          "regex": false,
          "value": ""
        },
        "searchValue": params?.entityName || "",
        "searchable": true
      }
    ],
    "order": [],
    "search": {
      "regex": true,
      "value": ""
    },
    "draw": params?.pageIndex ? params.pageIndex : 1,
    "length": params?.recordLength ? params.recordLength : -1,
  });

  return response?.data;
});

const userConfig = createSlice({
  name: "USER_CONFIG",
  initialState,
  reducers: {
    onChangeTableInputDetail: (state, action) => {
      const payload = action.payload;
      _.set(state.user.records.detail[payload.tableIndexRow], payload.tableRowName, parseInt(payload.value))
    },

    /*
      Location
    */
    /**
    * * ADD DATA TABLE USER CONFIGURATION *
    * Todo: to add table on User Configuration page
    * @param action
    */
    addDataTableUserConfig: (state, action) => {
      const payload = action.payload;

      state.user?.records.unshift(payload);
    },

    /**
    * * REMOVE DATA TABLE USER CONFIGURATION *
    * Todo: to remove row on table User Configuration page
    * @param action
    */
    onRemoveDataTableUserConfig: (state, action) => {
      const payload = action.payload;

      state.user.records.splice(payload.tableIndexRow, 1);
    },

    /**
    * * ONCHANGE TABLE USER CONFIGURATION *
    * Todo: to change table on User Configuration page
    * @param action
    */
    onChangeTableUserConfig: (state, action) => {
      const payload = action.payload;

      if (
        payload.tableRowName === "email" &&
        !state.user.records[payload.tableIndexRow]["old_email"]
      ) {
        state.user.records[payload.tableIndexRow]["old_email"] =
          state.user.records[payload.tableIndexRow][payload.tableRowName];
      }

      state.user.records[payload.tableIndexRow][payload.tableRowName] = payload.value;

      if (payload.tableRowName === "role") {
        state.user.records[payload.tableIndexRow]["permission"] = payload.permission
      }
    },

    addDataTableMember: (state, action) => {
      const payload = action.payload;

      if (payload.entity === "LoadingBlc") {
        state.userMemberBlc?.records.unshift(payload.data);
      }
      else if (payload.entity === "CrsuhingActual") {
        state.userMemberCrushing?.records.unshift(payload.data);
      }
      else if (payload.entity === "LoadingJetty") {
        state.userMemberJetty?.records.unshift(payload.data);
      }
      else if (payload.entity === "RomActual") {
        state.userMemberRom?.records.unshift(payload.data);
      }
    },

    onChangeTableMember: (state, action) => {
      const payload = action.payload;
      const records = payload.entity === "CrsuhingActual" ? state.userMemberCrushing?.records :
        payload.entity === "LoadingBlc" ? state.userMemberBlc?.records :
          payload.entity === "LoadingJetty" ? state.userMemberJetty?.records : state.userMemberRom?.records

      records[payload.tableIndexRow][payload.tableRowName] = payload.value;
      records[payload.tableIndexRow] = {
        ...records[payload.tableIndexRow],
        hasChange: true,
      };
    },

    onRemoveDataTableMember: (state, action) => {
      const payload = action.payload;
      const records = payload.entity === "CrsuhingActual" ? state.userMemberCrushing?.records :
        payload.entity === "LoadingBlc" ? state.userMemberBlc?.records :
          payload.entity === "LoadingJetty" ? state.userMemberJetty?.records : state.userMemberRom?.records

      records.splice(payload.tableIndexRow, 1);
    },

    onReset: (state, action) => {
      state.userMemberCrushing = {
        loading: false,
        error: "",
        records: [],
        recordsTotal: 0
      }
      state.userMemberBlc = {
        loading: false,
        error: "",
        records: [],
        recordsTotal: 0
      }
      state.userMemberJetty = {
        loading: false,
        error: "",
        records: [],
        recordsTotal: 0
      }
      state.userDetail = {
        loading: false,
        error: "",
        detail: {}
      }
      state.userMemberJetty = {
        loading: false,
        error: "",
        records: [],
        recordsTotal: 0
      }
      state.userMemberRom = {
        loading: false,
        error: "",
        records: [],
        recordsTotal: 0
      }
      state.actionRole = {
        loading: false,
        error: "",
        roles: []
      }
      state.userRole = []
    },

    onChangeUserRole: (state, action) => {
      const payload = action.payload;

      state.userDetail.detail.role = payload;
    },

    onSetUserDetail: (state, action) => {
      const payload = action.payload;

      state.userDetail.detail = payload;
    },

    onChangeStatus: (state, action) => {
      const payload = action.payload;
      let data = current(state.userDetail.detail.permission)
      const index = data.findIndex(e => e.name == payload.data.name)

      state.userDetail.detail.permission[index][payload.id] = payload.value;
    },

    onSelectAllModule: (state, action) => {
      const payload = action.payload;
      const role = payload.role
      let data = current(state.userDetail.detail.permission)
      let dataFilter = data.filter(e => e.modulCode == payload.data.modulCode)

      dataFilter.map((val) => {
        const index = data.findIndex(e => e.name == val.name)
        switch (role) {
          case "Viewer":
            state.userDetail.detail.permission[index]["view"] = payload.checked;
            break;
          case "Creator":
          case "Admin Contractor":
          case "Admin Mobile":
          case "Captain Contractor":
          case "Creator Contractor":
            state.userDetail.detail.permission[index]["view"] = payload.checked;
            state.userDetail.detail.permission[index]["create"] = payload.checked;
            break;
          case "Approver":
            state.userDetail.detail.permission[index]["view"] = payload.checked;
            state.userDetail.detail.permission[index]["approve"] = payload.checked;
            break;
          default:
            state.userDetail.detail.permission[index]["view"] = payload.checked;
            state.userDetail.detail.permission[index]["create"] = payload.checked;
            state.userDetail.detail.permission[index]["approve"] = payload.checked;
            break
        }
      })
    },

    onSelectAllFeature: (state, action) => {
      const payload = action.payload;
      const role = payload.role
      let data = current(state.userDetail.detail.permission)

      data = data.map(e => {
        switch (role) {
          case "Viewer":
            return { ...e, view: payload.checked }
          case "Creator":
          case "Admin Contractor":
          case "Admin Mobile":
          case "Captain Contractor":
          case "Creator Contractor":
            return { ...e, view: payload.checked, create: payload.checked }
          case "Approver":
            return { ...e, view: payload.checked, approve: payload.checked }
          default:
            return { ...e, view: payload.checked, create: payload.checked, approve: payload.checked }
        }
      })

      state.userDetail.detail.permission = data;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUserConfigurationList.pending, (state, action) => {
      state.user.loading = true;
    });
    builder.addCase(getUserConfigurationList.fulfilled, (state, action) => {
      state.userDetail.detail = action.payload.list[0];
      state.user.records = action.payload.list.map((list) => {
        return {
          ...list,
          oldEmail: list.email
        }
      })
      state.user.recordsTotal = action.payload.recordsTotal;
      state.user.loading = false;
    });
    builder.addCase(getUserConfigurationList.rejected, (state, action) => {
      state.user.loading = false;
      state.user.error = 'Invalid get data';
    });

    builder.addCase(getUserConfigurationLogs.pending, (state, action) => {
      state.user.loading = true;
    });
    builder.addCase(getUserConfigurationLogs.fulfilled, (state, action) => {
      state.user.statusLogList = action.payload.list;
      state.user.statusLogListTotal = action.payload.recordsTotal;
    });
    builder.addCase(getUserConfigurationLogs.rejected, (state, action) => {
      state.user.loading = false;
      state.user.error = 'Invalid get data';
    });

    builder.addCase(getUserMember.pending, (state, action) => { });
    builder.addCase(getUserMember.fulfilled, (state, action) => { });
    builder.addCase(getUserMember.rejected, (state, action) => { });

    builder.addCase(getUserMemberFilter.pending, (state, action) => {
      state.userMemberCrushing.loading = true;
      state.userMemberBlc.loading = true;
      state.userMemberJetty.loading = true;
      state.userMemberRom.loading = true;
    });
    builder.addCase(getUserMemberFilter.fulfilled, (state, action) => {
      const BlcActual = action.payload.list.filter(e => e.entityName === "LoadingBlc")
      const CrsuhingActual = action.payload.list.filter(e => e.entityName === "CrsuhingActual")
      const JettyActual = action.payload.list.filter(e => e.entityName === "LoadingJetty")
      const RomActual = action.payload.list.filter(e => e.entityName === "RomActual")

      if (BlcActual.length > 0) {
        BlcActual.sort((a, b) => (moment(a.updatedAt).isAfter(moment(b.updatedAt))) ? -1 : 1)
        state.userMemberBlc.records = BlcActual;
        state.userMemberBlc.recordsTotal = action.payload.recordsTotal;
      }
      else if (BlcActual.length === 0) {
        state.userMemberBlc.records = [];
        state.userMemberBlc.recordsTotal = 0;
      }

      if (CrsuhingActual.length > 0) {
        CrsuhingActual.sort((a, b) => (moment(a.updatedAt).isAfter(moment(b.updatedAt))) ? -1 : 1)
        state.userMemberCrushing.records = CrsuhingActual;
        state.userMemberCrushing.recordsTotal = action.payload.recordsTotal;
      }
      else if (CrsuhingActual.length === 0) {
        state.userMemberCrushing.records = [];
        state.userMemberCrushing.recordsTotal = 0;
      }

      if (JettyActual.length > 0) {
        JettyActual.sort((a, b) => (moment(a.updatedAt).isAfter(moment(b.updatedAt))) ? -1 : 1)
        state.userMemberJetty.records = JettyActual;
        state.userMemberJetty.recordsTotal = action.payload.recordsTotal;
      }
      else if (JettyActual.length === 0) {
        state.userMemberJetty.records = [];
        state.userMemberJetty.recordsTotal = 0;
      }

      if (RomActual.length > 0) {
        RomActual.sort((a, b) => (moment(a.updatedAt).isAfter(moment(b.updatedAt))) ? -1 : 1)
        state.userMemberRom.records = RomActual;
        state.userMemberRom.recordsTotal = action.payload.recordsTotal;
      }
      else if (RomActual.length === 0) {
        state.userMemberRom.records = [];
        state.userMemberRom.recordsTotal = 0;
      }

      state.userMemberCrushing.loading = false;
      state.userMemberBlc.loading = false;
      state.userMemberJetty.loading = false;
      state.userMemberRom.loading = false;
    });
    builder.addCase(getUserMemberFilter.rejected, (state, action) => {
      state.userMemberCrushing.loading = false;
      state.userMemberBlc.loading = false;
      state.userMemberJetty.loading = false;
      state.userMemberRom.loading = false;
      state.userMemberCrushing.error = 'Invalid get data';
    });

    builder.addCase(getAttendanceAll.pending, (state, action) => {
      state.userMemberCrushing.loading = true;
      state.userMemberBlc.loading = true;
      state.userMemberJetty.loading = true;
      state.userMemberRom.loading = true;
    });
    builder.addCase(getAttendanceAll.fulfilled, (state, action) => {
      const BlcActual = action.payload.list.filter(e => e.entityName === "LoadingBlc")
      const CrushingActual = action.payload.list.filter(e => e.entityName === "CrushingActual")
      const JettyActual = action.payload.list.filter(e => e.entityName === "LoadingJetty")
      const RomActual = action.payload.list.filter(e => e.entityName === "RomActual")

      if (BlcActual.length > 0) {
        BlcActual.sort((a, b) => (moment(a.updatedAt).isAfter(moment(b.updatedAt))) ? -1 : 1)
        state.userMemberBlc.records = BlcActual;
        state.userMemberBlc.recordsTotal = action.payload.recordsTotal;
      }
      else if (BlcActual.length === 0) {
        state.userMemberBlc.records = [];
        state.userMemberBlc.recordsTotal = action.payload.recordsTotal;
      }

      if (CrushingActual.length > 0) {
        CrushingActual.sort((a, b) => (moment(a.updatedAt).isAfter(moment(b.updatedAt))) ? -1 : 1)
        state.userMemberCrushing.records = CrushingActual;
        state.userMemberCrushing.recordsTotal = action.payload.recordsTotal;
      }
      else if (CrushingActual.length === 0) {
        state.userMemberCrushing.records = [];
        state.userMemberCrushing.recordsTotal = action.payload.recordsTotal;
      }

      if (JettyActual.length > 0) {
        JettyActual.sort((a, b) => (moment(a.updatedAt).isAfter(moment(b.updatedAt))) ? -1 : 1)
        state.userMemberJetty.records = JettyActual;
        state.userMemberJetty.recordsTotal = action.payload.recordsTotal;
      }
      else if (JettyActual.length === 0) {
        state.userMemberJetty.records = [];
        state.userMemberJetty.recordsTotal = action.payload.recordsTotal;
      }

      if (RomActual.length > 0) {
        RomActual.sort((a, b) => (moment(a.updatedAt).isAfter(moment(b.updatedAt))) ? -1 : 1)
        state.userMemberRom.records = RomActual;
        state.userMemberRom.recordsTotal = action.payload.recordsTotal;
      }
      else if (RomActual.length === 0) {
        state.userMemberRom.records = [];
        state.userMemberRom.recordsTotal = action.payload.recordsTotal;
      }

      state.userMemberCrushing.loading = false;
      state.userMemberBlc.loading = false;
      state.userMemberJetty.loading = false;
      state.userMemberRom.loading = false;
    });
    builder.addCase(getAttendanceAll.rejected, (state, action) => {
      state.userMemberCrushing.loading = false;
      state.userMemberBlc.loading = false;
      state.userMemberJetty.loading = false;
      state.userMemberRom.loading = false;
    });

    builder.addCase(getAttendanceRomAll.pending, (state, action) => {
      state.userMemberRom.loading = true;
    });
    builder.addCase(getAttendanceRomAll.fulfilled, (state, action) => {
      const RomActual = action.payload.list.filter(e => e.entityName === "RomActual")

      if (RomActual.length > 0) {
        RomActual.sort((a, b) => (moment(a.updatedAt).isAfter(moment(b.updatedAt))) ? -1 : 1)
        state.userMemberRom.records = RomActual;
        state.userMemberRom.recordsTotal = action.payload.recordsTotal;
      }
      else if (RomActual.length === 0) {
        state.userMemberRom.records = [];
        state.userMemberRom.recordsTotal = action.payload.recordsTotal;
      }

      state.userMemberRom.loading = false;
    });
    builder.addCase(getAttendanceRomAll.rejected, (state, action) => {
      state.userMemberRom.loading = false;
    });

    builder.addCase(getDetailUser.pending, (state, action) => {
      state.userDetail.loading = true;
    });
    builder.addCase(getDetailUser.fulfilled, (state, action) => {
      state.userDetail.detail.permission = action.payload.permission;
    });
    builder.addCase(getDetailUser.rejected, (state, action) => {
      state.userDetail.loading = false;
      state.userDetail.error = 'Invalid get data';
    });

    builder.addCase(getActionRole.pending, (state, action) => {
      state.userDetail.loading = true;
    });
    builder.addCase(getActionRole.fulfilled, (state, action) => {
      state.userDetail.detail.permission = action.payload;
    });
    builder.addCase(getActionRole.rejected, (state, action) => {
      state.userDetail.loading = false;
      state.userDetail.error = 'Invalid get data';
    });

    builder.addCase(getActionRoleList.fulfilled, (state, action) => {
      let data = action.payload
      switch (data[0]?.roleCode) {
        case "VIEWER":
          state.actionRoleViewer = data
          break
        case "CREATOR":
          state.actionRoleCreator = data
          break
        case "APPROVER":
          state.actionRoleApprover = data
          break
        case "CUSTOM":
          state.actionRoleCustom = data
          break
        case "ADMIN_BIB":
          state.actionRoleAdminBIB = data
          break
        case "ADMIN_CONTRACTOR":
          state.actionRoleAdminContractor = data
          break
        case "ADMIN_MOBILE":
          state.actionRoleAdminMobile = data
          break
        case "ADMIN_SUPER":
          state.actionRoleSuperAdmin = data
          break
      }
    });
  },
});

export const {
  onChangeTableInputDetail,
  onRemoveDataTableUserConfig,
  onChangeTableUserConfig,
  addDataTableUserConfig,
  addDataTableMember,
  onChangeTableMember,
  onRemoveDataTableMember,
  onReset,
  onChangeUserRole,
  onSetUserDetail,
  onChangeStatus,
  onSelectAllModule,
  onSelectAllFeature
} = userConfig.actions;
export const userConfigurationSelector = (state) => state.userConfig.user;
export const userMemberCrushingSelector = (state) => state.userConfig.userMemberCrushing;
export const userMemberBlcSelector = (state) => state.userConfig.userMemberBlc;
export const userMemberJettySelector = (state) => state.userConfig.userMemberJetty;
export const userMemberRomSelector = (state) => state.userConfig.userMemberRom;
export const userDetailSelector = (state) => state.userConfig.userDetail;
export const actionRoleViewerSelector = (state) => state.userConfig.actionRoleViewer;
export const actionRoleCreatorSelector = (state) => state.userConfig.actionRoleCreator;
export const actionRoleApproverSelector = (state) => state.userConfig.actionRoleApprover;
export const actionRoleCustomSelector = (state) => state.userConfig.actionRoleCustom;
export const actionRoleAdminBIBSelector = (state) => state.userConfig.actionRoleAdminBIB;
export const actionRoleAdminContractorSelector = (state) => state.userConfig.actionRoleAdminContractor;
export const actionRoleAdminMobileSelector = (state) => state.userConfig.actionRoleAdminMobile;
export const actionRoleSuperAdminSelector = (state) => state.userConfig.actionRoleSuperAdmin;

export default userConfig.reducer;
