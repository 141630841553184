import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit';
import { apiUrls, deleteRequest, get, patch, post, put } from 'utils/request';
import { DATASOURCE_VALUE } from 'utils/helper';

/* global BigInt */

const serviceName = 'payload';
export const initialState = {
  payloadList: {
    records: [],
    loading: false,
    error: null,
    recordsTotal: '',
  },
  payloadDetail: {
    data: {},
    records: [],
    response: [],
    loading: false,
    error: null,
    statusLogList: [],
    WasteDocumentList: [],
    CoalDocumentList: [],
  },
  payloadDetailTemplate: {
    data: [],
    records: [],
    loading: false,
    error: null,
  },
  payloadNew: {
    contractorCode: "",
    contractorName: "",
    notes: "",
    coalDetailList: [],
    wasteDetailList: []
  },
  payloadLatest: {
    records: [],
    loading: false,
    error: null,
  },
  contractorByArea: {
    records: [],
    loading: false,
    error: ""
  }
};

export const PAYLOAD_REDUCER = 'PAYLOAD_REDUCER';

export const fetchPayloadList = createAsyncThunk(
  'payload/list',
  async (params) => {
    const response = await post(`${apiUrls.payload}/filter`, {
      columns: [
        {
          data: 'payloadId',
          orderable: true,
          search: {
            regex: false,
            value: '',
          },
          searchValue: '',
          searchable: true,
        },
        {
          data: 'dataStatus',
          orderable: true,
          search: {
            regex: params?.dataStatus === DATASOURCE_VALUE.waitingApproval || params?.dataStatus >= 20 ? true : false,
            value: '',
          },
          searchValue: params.dataStatus || '',
          searchable: true,
        },
        {
          data: 'createdAt',
          orderable: true,
          search: {
            regex: true,
            value: '',
          },
          searchValue: params.date || '',
          searchable: true,
        },
        {
          data: 'contractorName',
          orderable: false,
          search: {
            regex: false,
          },
          searchValue: params.contractorName || '',
          searchable: true,
        },
        {
          data: 'contractorCode',
          orderable: true,
          search: {
            regex: false,
            value: '',
          },
          searchValue: params.contractor || '',
          searchable: true,
        },
      ],
      draw: params?.pageIndex ? params.pageIndex : 1,
      length: params?.dataLength ? params.dataLength : -1,
      order: [
        {
          "column": 2,
          "dir": "desc"
        }
      ],
      search: {
        regex: false,
        value: params.query || '',
      },
      start: 0,
    });
    return response?.data;
  }
);

export const deletePayload = createAsyncThunk(
  'payload/delete',
  async (param) => {
    const { id } = param;
    const response = await deleteRequest(
      `${apiUrls.payload}/${id}`
    );
    return response;
  }
);

export const setPayloadStatus = createAsyncThunk(
  'payload/setStatus',
  async (param) => {
    const { data } = param;
    const response = await patch(`${apiUrls.payload}/set-status`, {
      ...data,
    });
    return response.data;
  }
);

export const payloadDetailTemplate = createAsyncThunk(
  'payload/template',
  async (param) => {
    const response = await get(
      `${apiUrls.payload}/detail-template?contractorCode=${param.contractorCode}`
    );
    return response.data;
  }
);

export const getDetailService = createAsyncThunk(
  `${serviceName}/detail`,
  async (params) => {
    const { id } = params;
    const response = await get(`${apiUrls.payload}/${id}`);
    return response?.data;
  }
);

export const addService = createAsyncThunk(
  `${serviceName}/add`,
  async (param) => {
    const { data, username } = param;
    const response = await post(`${apiUrls.payload}/save?userName=${username}`, data);
    return response.data;
  }
);

export const saveService = createAsyncThunk(
  `${serviceName}/save`,
  async (param) => {
    const { data, username } = param;
    const response = await post(`${apiUrls.payload}/save?userName=${username}`, data);
    return response.data;
  }
);

export const submitService = createAsyncThunk(
  `${serviceName}/submit`,
  async (param) => {
    const { data, username } = param;
    const response = await post(`${apiUrls.payload}/submit?userName=${username}`, data);
    return response.data;
  }
);

export const uploadFileServiceCoal = createAsyncThunk(
  `${serviceName}/uploadFile-coal`,
  async (param) => {
    const { id, file } = param;
    const response = await post(`${apiUrls.payload}/${id}/coal/files`, file, {
      headers: { 'Content-Type': undefined },
    });
    return response.data;
  }
);

export const uploadFileServiceWaste = createAsyncThunk(
  `${serviceName}/uploadFile-waste`,
  async (param) => {
    const { id, file } = param;
    const response = await post(`${apiUrls.payload}/${id}/waste/files`, file, {
      headers: { 'Content-Type': undefined },
    });
    return response.data;
  }
);

export const deleteFileServiceCoal = createAsyncThunk(
  `${serviceName}/deleteFile-coal`,
  async (param) => {
    const { id, name } = param;
    const response = await deleteRequest(
      `${apiUrls.payload}/${id}/coal/files/${name}`
    );
    return response.data;
  }
);

export const deleteFileServiceWaste = createAsyncThunk(
  `${serviceName}/deleteFile-waste`,
  async (param) => {
    const { id, name } = param;
    const response = await deleteRequest(
      `${apiUrls.payload}/${id}/waste/files/${name}`
    );
    return response.data;
  }
);

export const updateDataService = createAsyncThunk(
  `${serviceName}/adminUpdate`,
  async (param) => {
    const { id, action, username, data } = param;
    const response = await put(
      `${apiUrls.payload}/${id}/admin/${action}?userName=${username}`, data
    );
    return response.data;
  }
);

export const getLatestPayload = createAsyncThunk(`${serviceName}/get-latest`, async (params) => {
  const { contractorCode } = params;
  const response = await get(`${apiUrls.payload}/latest-detail?contractorCode=${contractorCode}`);
  return response?.data;
});

export const fetchContractorByArea = createAsyncThunk(
  `master-data/area-by-name`,
  async (params) => {
    const { area } = params;
    const response = await get(`${apiUrls.masterData}/areas/names/${area}/contractors`);
    return response?.data;
  }
);

export const intervensiPayload = createAsyncThunk(
  `${serviceName}/intervensi`,
  async (params) => {
    let response

    try {
      response = await post(`${apiUrls.intervensiPayload}/user-role?moduleName=DefaultPayload`, params);
      return response?.data;
    }
    catch (err) {
      return err
    }
  }
);

const payloadSlice = createSlice({
  name: PAYLOAD_REDUCER,
  initialState,
  reducers: {
    onReset: (state, action) => {
      state.payloadDetail = {
        data: [],
        records: [],
        response: [],
        loading: false,
        error: null,
        statusLogList: [],
      }

      state.payloadList = {
        records: [],
        loading: false,
        error: null,
        recordsTotal: '',
      }
    },

    resetListLocation: (state, action) => {
      state.contractorByArea = {
        records: [],
        loading: false,
        error: "",
      }
    },

    onCreatePayloadNew: (state, action) => {
      const payload = action.payload
      const data = current(state.payloadDetailTemplate.data)
      let dataWaste = data.filter(e => e.material.type === "Waste") || [];
      let dataCoal = data.filter(e => e.material.type === "Coal") || [];

      const filteredLocation = payload.location.filter((value, index, self) =>
        index === self.findIndex((t) => (
          t.id === value.id && t.code === value.code
        ))
      )

      const dataCoalFilter = dataCoal.map(e => {
        let equipmentArr = []
        e.equipment.map(val => {
          return equipmentArr.push({ ...val, value: null })
        })

        return { ...e, equipment: equipmentArr }
      })

      const dataWasteFilter = dataWaste.map(e => {
        let equipmentArr = []
        e.equipment.map(val => {
          return equipmentArr.push({ ...val, value: null })
        })

        return { ...e, equipment: equipmentArr }
      })

      let coalPayload = []
      let wastePayload = []

      filteredLocation.map(e => {
        if (dataCoalFilter.length > 0) {
          coalPayload.push({ location: e, locationPayloadList: dataCoalFilter })
        }

        if (dataWasteFilter.length > 0) {
          wastePayload.push({ location: e, locationPayloadList: dataWasteFilter })
        }
      })

      const latestPayload = current(state.payloadLatest.records)
      let finalWastePayload = [];
      let finalCoalPayload = [];
      // console.log("latestPayload", latestPayload);

      if (latestPayload?.length > 0) {
        finalWastePayload = wastePayload.map(wp => {
          let arrLocPayload = [];
          wp.locationPayloadList.map(val => {
            let arrEquipment = []
            val.equipment.map(eq => {
              const idxDefPayload = latestPayload.findIndex(el => el.type === "Waste" && el.locationName === wp.location.name && el.materialId === val.material.id && el.equipmentId === eq.id)
              return arrEquipment.push({ ...eq, value: idxDefPayload >= 0 ? latestPayload[idxDefPayload].value : null })
            })

            return arrLocPayload.push({ ...val, equipment: arrEquipment })
          })

          return { ...wp, locationPayloadList: arrLocPayload }
        })

        finalCoalPayload = coalPayload.map(wp => {
          let arrLocPayload = [];
          wp.locationPayloadList.map(val => {
            let arrEquipment = []
            val.equipment.map(eq => {
              const idxDefPayload = latestPayload.findIndex(el => el.type === "Coal" && el.locationName === wp.location.name && el.materialId === val.material.id && el.equipmentId === eq.id)
              return arrEquipment.push({ ...eq, value: idxDefPayload >= 0 ? latestPayload[idxDefPayload].value : null })
            })

            return arrLocPayload.push({ ...val, equipment: arrEquipment })
          })

          return { ...wp, locationPayloadList: arrLocPayload }
        })
      }

      state.payloadNew.coalDetailList = finalCoalPayload.length > 0 ? finalCoalPayload : coalPayload
      state.payloadNew.wasteDetailList = finalWastePayload.length > 0 ? finalWastePayload : wastePayload
      state.payloadDetailTemplate.loading = false;
    },

    onChangePayload: (state, action) => {
      const payload = action.payload
      const payloadName = payload.name.split(";;")
      const data = payload.isCreate && !payload.lastData ? current(state.payloadNew) : current(state.payloadDetail.data)
      let dataIndex = -1, equipmentIndex = -1;

      if (payload.activeTab === "coal") {
        dataIndex = data?.coalDetailList?.findIndex((e) => e?.location?.id === parseInt(payloadName[0].replace("__", "")))
        if (dataIndex !== -1) {
          equipmentIndex = data?.coalDetailList[dataIndex]?.locationPayloadList[0]?.equipment?.findIndex((e) => e?.equipmentClass === payloadName[1])
        }

        if (dataIndex !== -1 && equipmentIndex !== -1) {
          if (payload.isCreate && !payload.lastData) {
            state.payloadNew.coalDetailList[dataIndex].locationPayloadList[0].equipment[equipmentIndex].value = payload.value
          }
          else {
            state.payloadDetail.data.coalDetailList[dataIndex].locationPayloadList[0].equipment[equipmentIndex].value = payload.value
          }
        }
      }

      else if (payload.activeTab === "waste") {
        dataIndex = data?.wasteDetailList?.findIndex((e) => e?.location?.id === parseInt(payloadName[0].replace("__", "")))
        if (dataIndex !== -1) {
          equipmentIndex = data?.wasteDetailList[dataIndex]?.locationPayloadList[payloadName[3]]?.equipment?.findIndex((e) => e?.equipmentClass === payloadName[1])
        }

        if (dataIndex !== -1 && equipmentIndex !== -1) {
          if (payload.isCreate && !payload.lastData) {
            state.payloadNew.wasteDetailList[dataIndex].locationPayloadList[payloadName[3]].equipment[equipmentIndex].value = payload.value
          }
          else {
            state.payloadDetail.data.wasteDetailList[dataIndex].locationPayloadList[payloadName[3]].equipment[equipmentIndex].value = payload.value
          }
        }
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPayloadList.pending, (state, action) => {
      state.payloadList.loading = true;
    });
    builder.addCase(fetchPayloadList.fulfilled, (state, action) => {
      state.payloadList.records = action.payload.list;
      state.payloadList.recordsTotal = action.payload.recordsTotal;
      state.payloadList.loading = false;
    });
    builder.addCase(fetchPayloadList.rejected, (state, action) => {
      state.payloadList.loading = false;
      state.payloadList.error = action.error;
    });

    builder.addCase(getDetailService.pending, (state, action) => {
      state.payloadDetail.loading = true;
    });
    builder.addCase(
      getDetailService.fulfilled,
      (state, action) => {
        let data = action.payload;
        if (action.payload.coalDetailList.length > 0 || action.payload.wasteDetailList.length > 0) {
          const changeFormat = (val) => {
            let value = 0;

            if (val) {
              value = BigInt(
                parseFloat(val).toFixed(3).toString().replaceAll(".", "").replaceAll(",", "")
              ).toLocaleString("fullwide", { useGrouping: false });
            }

            if (value < 10) {
              value = "0,00" + value;
            } else if (value < 100) {
              value = "0,0" + value;
            } else if (value > 9.0071993e18) {
              return;
            } else {
              value = value.toString();
              const lastThree = value.slice(-3);
              const remains = value.substring(0, value.length - 3);
              value =
                Number(remains).toLocaleString().replaceAll(",", ".") +
                "," +
                lastThree;
            }
            return value;
          }

          const dtWaste = action.payload.wasteDetailList.map(dt => {
            return {
              ...dt,
              locationPayloadList: dt.locationPayloadList.map(lpl => {
                return {
                  ...lpl,
                  equipment: lpl.equipment.map(eq => {
                    return {
                      ...eq,
                      value: changeFormat(eq.value)
                    }
                  })
                }
              })
            }
          });

          const dtCoal = action.payload.coalDetailList.map(dt => {
            return {
              ...dt,
              locationPayloadList: dt.locationPayloadList.map(lpl => {
                return {
                  ...lpl,
                  equipment: lpl.equipment.map(eq => {
                    return {
                      ...eq,
                      value: changeFormat(eq.value)
                    }
                  })
                }
              })
            }
          });

          data = { ...action.payload, wasteDetailList: dtWaste, coalDetailList: dtCoal };
        }

        state.payloadDetail.records = action.payload.detail;
        state.payloadDetail.data = data;
        state.payloadDetail.response = data;
        state.payloadDetail.statusLogList =
          action.payload.statusLogList;
        state.payloadDetail.loading = false;
      }
    );
    builder.addCase(
      getDetailService.rejected,
      (state, action) => {
        state.payloadDetail.loading = false;
        state.payloadDetail.error = action.error;
      }
    );

    builder.addCase(
      payloadDetailTemplate.pending,
      (state, action) => {
        state.payloadDetailTemplate.loading = true;
      }
    );
    builder.addCase(
      payloadDetailTemplate.fulfilled,
      (state, action) => {
        let payload = action.payload

        // TODO remove after equipment class is unique

        let equipment = payload.map(e => {
          return e.equipment
        })

        equipment = equipment.map(e => {
          let filter = e.filter((v, i, a) => a.findIndex(v2 => (v2.equipmentClass === v.equipmentClass)) === i)
          return filter
        })

        payload.map((e, index) => {
          payload[index].equipment = equipment[index]
        })

        //

        payload.map(e => {
          e.equipment.map(val => {
            val.material = e.material
          })
        })

        state.payloadDetailTemplate.data = payload;
        state.payloadDetailTemplate.records = action.payload;
      }
    );
    builder.addCase(
      payloadDetailTemplate.rejected,
      (state, action) => {
        state.payloadDetailTemplate.loading = false;
        state.payloadDetailTemplate.error = action.error;
      }
    );

    builder.addCase(getLatestPayload.pending, (state, action) => {
      state.payloadLatest.loading = true;
    });
    builder.addCase(getLatestPayload.fulfilled, (state, action) => {
      state.payloadLatest.records = action?.payload;
      state.payloadLatest.loading = false;
    });
    builder.addCase(getLatestPayload.rejected, (state, action) => {
      state.payloadLatest.loading = false;
      state.payloadLatest.error = 'Invalid get data';
    });

    builder.addCase(fetchContractorByArea.pending, (state, action) => {
      state.contractorByArea.loading = true;
    }
    );
    builder.addCase(fetchContractorByArea.fulfilled, (state, action) => {
      state.contractorByArea.records = action.payload;
    }
    );
    builder.addCase(fetchContractorByArea.rejected, (state, action) => {
      state.contractorByArea.loading = false;
      state.contractorByArea.error = action.error;
    }
    );
  },
});
export const payloadSelector = (state) =>
  state.payload.payloadList;
export const payloadDetailSelector = (state) =>
  state.payload.payloadDetail;
export const payloadTemplateSelector = (state) =>
  state.payload.payloadDetailTemplate;
export const payloadNewSelector = (state) =>
  state.payload.payloadNew;
export const latestPayloadSelector = (state) => state.payload.payloadLatest;
export const contractorByArea = (state) =>
  state.payload.contractorByArea;

export const {
  onChangePayload,
  onReset,
  onCreatePayloadNew,
  resetListLocation,
} = payloadSlice.actions;

export default payloadSlice.reducer;
