import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import { getAPIEndpoint, apiUrls, get, post } from "utils/request";
import { isNumeric } from "utils/helper";
var _ = require("lodash");

export const initialState = {
  materials: {
    loading: false,
    records: [],
    revert: [],
    onRevert: false,
    error: "",
    recordsTotal: "",
    statusLogList: [],
    statusLogListTotal: 0,
  },
  contractors: {
    loading: false,
    records: [],
    revert: [],
    onRevert: false,
    error: "",
    recordsTotal: "",
    statusLogList: [],
    statusLogListTotal: 0,
    nameList: [],
    codeList: [],
  },
  productionStatus: {
    loading: false,
    records: [],
    error: "",
    recordsTotal: "",
  },
  configurationContractor: {
    loading: false,
    records: [],
    revert: [],
    onRevert: false,
    error: "",
    recordsTotal: "",
    statusLogList: [],
    statusLogListTotal: 0,
  },
  loadingDumping: {
    loading: false,
    records: [],
    revert: [],
    onRevert: false,
    error: "",
    recordsTotal: "",
    statusLogList: [],
    statusLogListTotal: 0,
  },
  crusher: {
    loading: false,
    records: [],
    revert: [],
    onRevert: false,
    error: "",
    recordsTotal: "",
    statusLogList: [],
    statusLogListTotal: 0,
    designCapacityList: [],
  },
  RomPort: {
    loading: false,
    records: [],
    revert: [],
    onRevert: false,
    error: "",
    recordsTotal: "",
    statusLogList: [],
    statusLogListTotal: 0,
    designCapacityList: [],
  },
  jetty: {
    loading: false,
    records: [],
    revert: [],
    onRevert: false,
    error: "",
    recordsTotal: "",
    statusLogList: [],
    statusLogListTotal: 0,
    designCapacityList: [],
  },
  jettyconf: {
    loading: false,
    records: [],
    revert: [],
    onRevert: false,
    error: "",
    recordsTotal: "",
    statusLogList: [],
    statusLogListTotal: 0,
  },
  subLocations: {
    loading: false,
    records: [],
    revert: [],
    onRevert: false,
    error: "",
    recordsTotal: "",
    statusLogList: [],
    statusLogListTotal: 0,
    nameList: [],
  },
  subLocationsDumping: {
    loading: false,
    records: [],
    revert: [],
    onRevert: false,
    error: "",
    recordsTotal: "",
    statusLogList: [],
    statusLogListTotal: 0,
  },
  elevations: {
    loading: false,
    records: [],
    error: "",
  },
  locations: {
    loading: false,
    records: [],
    revert: [],
    onRevert: false,
    error: "",
    recordsTotal: "",
    statusLogList: [],
    statusLogListTotal: 0,
    nameList: [],
    codeList: [],
  },
  locationsAll: {
    loading: false,
    locations: [],
    error: "",
  },
  equipments: {
    loading: false,
    records: [],
    revert: [],
    onRevert: false,
    error: "",
    recordsTotal: "",
    statusLogList: [],
    statusLogListTotal: 0,
    typeList: [],
    classList: [],
    brandList: [],
  },
  equipmentRom: {
    loading: false,
    records: [],
    revert: [],
    onRevert: false,
    error: "",
    recordsTotal: "",
    statusLogList: [],
    statusLogListTotal: 0,
    jobList: [],
  },
  equipmentHaul: {
    loading: false,
    records: [],
    revert: [],
    onRevert: false,
    error: "",
    recordsTotal: "",
    statusLogList: [],
    statusLogListTotal: 0,
    axleList: [],
  },
  equipmentHaulConf: {
    loading: false,
    records: [],
    revert: [],
    onRevert: false,
    error: "",
    recordsTotal: "",
    statusLogList: [],
    statusLogListTotal: 0,
  },
  equipmentDetails: {
    loading: false,
    records: [],
    revert: [],
    onRevert: false,
    error: "",
    recordsTotal: "",
    statusLogList: [],
    statusLogListTotal: 0,
  },
  equipmentDetailsSub: {
    loading: false,
    records: [],
    revert: [],
    onRevert: false,
    error: "",
    recordsTotal: "",
    statusLogList: [],
    statusLogListTotal: 0,
  },
  areas: {
    loading: false,
    records: [],
    revert: [],
    onRevert: false,
    error: "",
    recordsTotal: "",
    statusLogList: [],
    statusLogListTotal: 0,
    listNames: [],
  },
  listOptions: {},
  pitList: [],
  locationsByNames: {
    loading: false,
    locations: [],
    error: "",
  },
  contractorsCode: {
    loading: false,
    records: [],
    revert: [],
    onRevert: false,
    error: "",
    recordsTotal: "",
    statusLogList: [],
    statusLogListTotal: 0,
    nameList: [],
    codeList: [],
  },
  contractorByArea: {
    records: [],
    loading: false,
    error: ""
  },
};

export const getMasterDataAreas = createAsyncThunk(
  "masterData/areas",
  async (params) => {
    const response = await post(`${apiUrls.masterData}/areas/filter`, {
      columns: [
        {
          data: "id",
          orderable: true,
          search: {
            regex: false,
            value: "",
          },
          searchValue: "",
          searchable: true,
        },
        {
          data: "name",
          orderable: true,
          search: {
            regex: false,
            value: "",
          },
          searchValue: params.area || "",
          searchable: true,
        },
        {
          data: "bibNotes",
          orderable: true,
          search: {
            regex: false,
            value: "",
          },
          searchValue: "",
          searchable: true,
        },
        {
          data: "entityStatus",
          orderable: true,
          search: {
            regex: false,
            value: "",
          },
          searchValue: params.status,
          searchable: true,
        },
        {
          data: "dataStatus",
          orderable: true,
          search: {
            regex: true,
            value: params.statusRegex || "",
          },
          searchValue: params.dataStatus || "",
          searchable: true,
        },
      ],
      search: {
        regex: false,
        value: params.name || "",
      },
      draw: params?.pageIndex ? params.pageIndex : 1,
      length: params?.recordLength ? params.recordLength : -1,
      start: 0,
    });
    return response?.data;
  }
);

export const getMasterDataAreaLogs = createAsyncThunk(
  "masterData/areas/logs",
  async (params) => {
    const response = await post(`${apiUrls.masterData}/areas/logs`, {
      columns: [],
      search: {},
      draw: params?.pageIndex ? params.pageIndex : 1,
      length: params?.recordLength ? params.recordLength : 10,
      start: 0,
    });
    return response?.data;
  }
);

export const getMasterDataCrusher = createAsyncThunk(
  "masterData/crushers",
  async (params) => {
    const response = await post(`${apiUrls.mdPortCrusher}/filter`, {
      columns: [
        {
          data: "id",
          orderable: true,
          search: {
            regex: false,
            value: "",
          },
          searchValue: "",
          searchable: false,
        },
        {
          data: "port",
          orderable: true,
          search: {
            regex: true,
            value: "",
          },
          searchValue: params.port || "",
          searchable: true,
        },
        {
          data: "plant",
          orderable: true,
          search: {
            regex: false,
            value: "",
          },
          searchValue: "",
          searchable: true,
        },
        {
          data: "designCapacity",
          orderable: true,
          search: {
            regex: false,
            value: "",
          },
          searchValue: params.designCapacity || "",
          searchable: true,
        },
        {
          data: "bibNotes",
          orderable: true,
          search: {
            regex: false,
            value: "",
          },
          searchValue: "",
          searchable: true,
        },
        {
          data: "entityStatus",
          orderable: true,
          search: {
            regex: false,
            value: "",
          },
          searchValue: params.status,
          searchable: true,
        },
        {
          data: "dataStatus",
          orderable: true,
          search: {
            regex: true,
            value: params.statusRegex || "",
          },
          searchValue: params.dataStatus || "",
          searchable: true,
        },
      ],
      search: {
        regex: false,
        value: params.query || "",
      },
      draw: params?.pageIndex ? params.pageIndex : 1,
      length: params?.recordLength ? params.recordLength : -1,
      start: 0,
    });
    return response?.data;
  }
);

export const getMasterDataRomPort = createAsyncThunk(
  "masterData/RomPort",
  async (params) => {
    const response = await post(`${apiUrls.mdRom}/filter`, {
      columns: [
        {
          data: "id",
          orderable: true,
          search: {
            regex: false,
            value: "",
          },
          searchValue: "",
          searchable: false,
        },
        {
          data: "port",
          orderable: true,
          search: {
            regex: true,
            value: "",
          },
          searchValue: params.port || "",
          searchable: true,
        },
        {
          data: "rom",
          orderable: true,
          search: {
            regex: false,
            value: "",
          },
          searchValue: "",
          searchable: true,
        },
        {
          data: "designCapacity",
          orderable: true,
          search: {
            regex: false,
            value: "",
          },
          searchValue: params.designCapacity || "",
          searchable: true,
        },
        {
          data: "bibNotes",
          orderable: true,
          search: {
            regex: false,
            value: "",
          },
          searchValue: "",
          searchable: true,
        },
        {
          data: "entityStatus",
          orderable: true,
          search: {
            regex: false,
            value: "",
          },
          searchValue: params.status,
          searchable: true,
        },
        {
          data: "dataStatus",
          orderable: true,
          search: {
            regex: true,
            value: params.statusRegex || "",
          },
          searchValue: params.dataStatus || "",
          searchable: true,
        },
      ],
      search: {
        regex: false,
        value: params.query || "",
      },
      draw: params?.pageIndex ? params.pageIndex : 1,
      length: params?.recordLength ? params.recordLength : -1,
      start: 0,
    });
    return response?.data;
  }
);

export const getMasterDataCrusherLogs = createAsyncThunk(
  "masterData/crushers/logs",
  async (params) => {
    const response = await post(`${apiUrls.mdPortCrusher}/logs`, {
      draw: params?.pageIndex ? params.pageIndex : 1,
      columns: [
        {
          data: "id",
          name: "",
          searchable: true,
          orderable: true,
          search: {
            value: "",
            regex: false,
          },
        },
      ],
      order: [
        {
          column: 0,
          dir: "desc",
        },
      ],
      start: 0,
      length: params?.recordLength ? params.recordLength : 10,
      search: {
        value: "",
        regex: false,
      },
    });
    return response?.data;
  }
);

export const getMasterDataRomPortLogs = createAsyncThunk(
  "masterData/RomPort/logs",
  async (params) => {
    const response = await post(`${apiUrls.mdRom}/logs`, {
      draw: params?.pageIndex ? params.pageIndex : 1,
      columns: [
        {
          data: "id",
          name: "",
          searchable: true,
          orderable: true,
          search: {
            value: "",
            regex: false,
          },
        },
      ],
      order: [
        {
          column: 0,
          dir: "desc",
        },
      ],
      start: 0,
      length: params?.recordLength ? params.recordLength : 10,
      search: {
        value: "",
        regex: false,
      },
    });
    return response?.data;
  }
);

export const getMasterDataJetty = createAsyncThunk(
  "masterData/jetties",
  async (params) => {
    const response = await post(`${apiUrls.mdPortJetty}/filter`, {
      columns: [
        {
          data: "id",
          orderable: true,
          search: {
            regex: false,
            value: "",
          },
          searchValue: "",
          searchable: true,
        },
        {
          data: "port",
          orderable: true,
          search: {
            regex: true,
            value: "",
          },
          searchValue: params.port || "",
          searchable: true,
        },
        {
          data: "jettyLoading",
          orderable: true,
          search: {
            regex: false,
            value: "",
          },
          searchValue: "",
          searchable: true,
        },
        {
          data: "designCapacity",
          orderable: true,
          search: {
            regex: false,
            value: "",
          },
          searchValue: params.designCapacity || "",
          searchable: true,
        },
        {
          data: "bibNotes",
          orderable: true,
          search: {
            regex: false,
            value: "",
          },
          searchValue: "",
          searchable: true,
        },
        {
          data: "entityStatus",
          orderable: true,
          search: {
            regex: false,
            value: "",
          },
          searchValue: params.status,
          searchable: true,
        },
        {
          data: "dataStatus",
          orderable: true,
          search: {
            regex: true,
            value: params.statusRegex || "",
          },
          searchValue: params.dataStatus || "",
          searchable: true,
        },
      ],
      search: {
        regex: true,
        value: params.query || "",
      },
      draw: params?.pageIndex ? params.pageIndex : 1,
      length: params?.recordLength ? params.recordLength : -1,
      start: 0,
    });
    return response?.data;
  }
);

export const getMasterDataJettyconf = createAsyncThunk(
  "jettyconf/get",
  async (params) => {
    const response = await post(`${apiUrls.mdPortJetty}/filter`, {
      draw: 1,
      length: -1,
      start: 0,
    });
    var filtered = response.data.list.map((v) => {
      return {
        designCapacity: v.designCapacity,
        jettyLoading: v.jettyLoading,
        percentage: 0,
        port: v.port.name,
        productivityTarget: 0,
        bib: {
          eu: 0,
          ma: 0,
          pa: 0,
          ua: 0,
        },
        kepmen1827: {
          eu: 0,
          ma: 0,
          pa: 0,
          ua: 0,
        },
      };
    });
    response.data.list = filtered;
    return response?.data;
  }
);

export const getMasterDataJettyLogs = createAsyncThunk(
  "masterData/jetties/logs",
  async (params) => {
    const response = await post(`${apiUrls.mdPortJetty}/logs`, {
      draw: params?.pageIndex ? params.pageIndex : 1,
      columns: [
        {
          data: "id",
          name: "",
          searchable: true,
          orderable: true,
          search: {
            value: "",
            regex: false,
          },
        },
      ],
      order: [
        {
          column: 0,
          dir: "desc",
        },
      ],
      start: 0,
      length: params?.recordLength ? params.recordLength : 10,
      search: {
        value: "",
        regex: false,
      },
    });
    return response?.data;
  }
);

export const getMasterDataMaterial = createAsyncThunk(
  "masterData/materials",
  async (params) => {
    const response = await post(`${apiUrls.masterData}/materials/filter`, {
      columns: [
        {
          data: "id",
          orderable: true,
          search: {
            regex: false,
            value: "",
          },
          searchValue: "",
          searchable: true,
        },
        {
          data: "contractor.name",
          orderable: true,
          search: {
            regex: false,
            value: "",
          },
          searchValue: params.contractor || "",
          searchable: true,
        },
        {
          data: "type",
          orderable: true,
          search: {
            regex: false,
            value: "",
          },
          searchValue: params.type,
          searchable: true,
        },
        {
          data: "name",
          orderable: true,
          search: {
            regex: false,
            value: "",
          },
          searchValue: params.name,
          searchable: true,
        },
        {
          data: "dataStatus",
          orderable: true,
          search: {
            regex: true,
            value: params.statusRegex || "",
          },
          searchValue: params.dataStatus || "",
          searchable: true,
        },
        {
          data: "entityStatus",
          orderable: true,
          search: {
            regex: false,
            value: "",
          },
          searchValue: params.status || "",
          searchable: true,
        },
        {
          data: "contractorNotes",
          orderable: true,
          search: {
            regex: false,
            value: "",
          },
          searchValue: "",
          searchable: true,
        },
        {
          data: "bibNotes",
          orderable: true,
          search: {
            regex: false,
            value: "",
          },
          searchValue: "",
          searchable: true,
        },
      ],
      draw: params?.pageIndex ? params.pageIndex : 1,
      length: params?.recordLength ? params.recordLength : -1,
      search: {
        regex: false,
        value: params.query || "",
      },
      start: 0,
    });
    return response?.data;
  }
);

export const getMasterDataMaterialLogs = createAsyncThunk(
  "masterData/materials/logs",
  async (params) => {
    const response = await post(`${apiUrls.masterData}/materials/logs`, {
      columns: [],
      search: {},
      draw: params?.pageIndex ? params.pageIndex : 1,
      length: params?.recordLength ? params.recordLength : 10,
      start: 0,
    });
    return response?.data;
  }
);

export const getMasterDataEquipmentDetails = createAsyncThunk(
  "masterData/equipmentdetails",
  async (params) => {
    const response = await post(
      `${apiUrls.masterData}/equipment-details/filter`,
      {
        columns: [
          {
            data: "id",
            orderable: true,
            search: {
              regex: false,
              value: "",
            },
            searchValue: "",
            searchable: true,
          },
          {
            data: "equipment.contractorName",
            orderable: true,
            search: {
              regex: false,
              value: "",
            },
            searchValue: params.contractorName || "",
            searchable: true,
          },
          {
            data: "equipment.type",
            orderable: true,
            search: {
              regex: !!params?.typeRegex,
            },
            searchValue: params.typeRegex || params.type || "",
            searchable: true,
          },
          {
            data: "equipmentClass",
            orderable: true,
            search: {
              regex: false,
              value: "",
            },
            searchValue: params.class || "",
            searchable: true,
          },
          {
            data: "brand",
            orderable: true,
            search: {
              regex: false,
              value: "",
            },
            searchValue: "",
            searchable: true,
          },
          {
            data: "equipmentDetailId",
            orderable: true,
            search: {
              regex: false,
              value: "",
            },
            searchValue: params.equipmentId || "",
            searchable: true,
          },
          {
            data: "engineNumber",
            orderable: true,
            search: {
              regex: false,
              value: "",
            },
            searchValue: "",
            searchable: true,
          },
          {
            data: "chasisNumber",
            orderable: true,
            search: {
              regex: false,
              value: "",
            },
            searchValue: "",
            searchable: true,
          },
          {
            data: "job",
            orderable: true,
            search: {
              regex: false,
              value: "",
            },
            searchValue: params.job || "",
            searchable: true,
          },
          {
            data: "bibNotes",
            orderable: true,
            search: {
              regex: false,
              value: "",
            },
            searchValue: "",
            searchable: true,
          },
          {
            data: "contractorNotes",
            orderable: true,
            search: {
              regex: false,
              value: "",
            },
            searchValue: "",
            searchable: true,
          },
          {
            data: "entityStatus",
            orderable: true,
            search: {
              regex: false,
              value: "",
            },
            searchValue: params.status,
            searchable: true,
          },
          {
            data: "dataStatus",
            orderable: true,
            search: {
              regex: true,
              value: params.statusRegex || "",
            },
            searchValue: params.dataStatus || "",
            searchable: true,
          },
          {
            data: "equipment.contractorCode",
            orderable: true,
            search: {
              regex: false,
              value: "",
            },
            searchValue: params.code || "",
            searchable: true,
          },
        ],
        search: {
          regex: false,
          value: params.query || "",
        },
        draw: params?.pageIndex ? params.pageIndex : 1,
        length: params?.recordLength ? params.recordLength : -1,
        start: 0,
      }
    );

    return response?.data;
  }
);

export const getMasterDataEquipmentDetailsSub = createAsyncThunk(
  "masterData/equipmentdetailsub",
  async (params) => {
    const response = await post(
      `${apiUrls.masterData}/equipment-details/filter`,
      {
        columns: [
          {
            data: "id",
            orderable: true,
            search: {
              regex: false,
              value: "",
            },
            searchValue: "",
            searchable: true,
          },
          {
            data: "equipment.contractorName",
            orderable: true,
            search: {
              regex: false,
              value: "",
            },
            searchValue: params.contractor || "",
            searchable: true,
          },
          {
            data: "equipment.type",
            orderable: true,
            search: {
              regex: !!params?.typeRegex,
            },
            searchValue: params.typeRegex || params.type || "",
            searchable: true,
          },
          {
            data: "equipmentClass",
            orderable: true,
            search: {
              regex: false,
              value: "",
            },
            searchValue: params.class || "",
            searchable: true,
          },
          {
            data: "brand",
            orderable: true,
            search: {
              regex: false,
              value: "",
            },
            searchValue: "",
            searchable: true,
          },
          {
            data: "equipmentDetailId",
            orderable: true,
            search: {
              regex: false,
              value: "",
            },
            searchValue: params.equipmentId || "",
            searchable: true,
          },
          {
            data: "engineNumber",
            orderable: true,
            search: {
              regex: false,
              value: "",
            },
            searchValue: "",
            searchable: true,
          },
          {
            data: "chasisNumber",
            orderable: true,
            search: {
              regex: false,
              value: "",
            },
            searchValue: "",
            searchable: true,
          },
          {
            data: "job",
            orderable: true,
            search: {
              regex: false,
              value: "",
            },
            searchValue: params.job || "",
            searchable: true,
          },
          {
            data: "bibNotes",
            orderable: true,
            search: {
              regex: false,
              value: "",
            },
            searchValue: "",
            searchable: true,
          },
          {
            data: "contractorNotes",
            orderable: true,
            search: {
              regex: false,
              value: "",
            },
            searchValue: "",
            searchable: true,
          },
          {
            data: "entityStatus",
            orderable: true,
            search: {
              regex: false,
              value: "",
            },
            searchValue: params.status,
            searchable: true,
          },
          {
            data: "dataStatus",
            orderable: true,
            search: {
              regex: true,
              value: params.statusRegex || "",
            },
            searchValue: params.dataStatus || "",
            searchable: true,
          },
        ],
        search: {
          regex: false,
          value: params.query || "",
        },
        draw: params?.pageIndex ? params.pageIndex : 1,
        length: params?.recordLength ? params.recordLength : -1,
        start: 0,
      }
    );

    return response?.data;
  }
);

export const getMasterDataEquipmentDetailsLogs = createAsyncThunk(
  "masterData/equipment-details/logs",
  async (params) => {
    const response = await post(
      `${apiUrls.masterData}/equipment-details/logs`,
      {
        draw: params?.pageIndex ? params.pageIndex : 1,
        columns: [
          {
            data: "id",
            name: "",
            searchable: true,
            orderable: true,
            search: {
              value: "",
              regex: false,
            },
          },
        ],
        order: [
          {
            column: 0,
            dir: "desc",
          },
        ],
        start: 0,
        length: params?.recordLength ? params.recordLength : 10,
        search: {
          value: "",
          regex: false,
        },
      }
    );
    return response?.data;
  }
);

export const getMasterDataEquipmentRom = createAsyncThunk(
  "masterData/equipmentRom",
  async (params) => {
    const response = await post(`${apiUrls.masterData}/equipment-rom/filter`, {
      columns: [
        {
          data: "id",
          orderable: true,
          search: {
            regex: false,
            value: "",
          },
          searchValue: "",
          searchable: true,
        },
        {
          data: "contractor",
          orderable: true,
          search: {
            regex: params.contractorRegex || false,
            value: "",
          },
          searchValue: params.contractor || "",
          searchable: true,
        },
        {
          data: "equipment.type",
          orderable: true,
          search: {
            regex: false,
            value: "",
          },
          searchValue: params.type || "",
          searchable: true,
        },
        {
          data: "equipmentClass",
          orderable: true,
          search: {
            regex: false,
            value: "",
          },
          searchValue: params.class || "",
          searchable: true,
        },
        {
          data: "equipmentRomId",
          orderable: true,
          search: {
            regex: false,
            value: "",
          },
          searchValue: params.equipmentId || "",
          searchable: true,
        },
        {
          data: "engineNumber",
          orderable: true,
          search: {
            regex: false,
            value: "",
          },
          searchValue: "",
          searchable: true,
        },
        {
          data: "chasisNumber",
          orderable: true,
          search: {
            regex: false,
            value: "",
          },
          searchValue: "",
          searchable: true,
        },
        {
          data: "brand",
          orderable: true,
          search: {
            regex: false,
            value: "",
          },
          searchValue: "",
          searchable: true,
        },
        {
          data: "job",
          orderable: true,
          search: {
            regex: false,
            value: "",
          },
          searchValue: params.job || "",
          searchable: true,
        },
        {
          data: "bibNotes",
          orderable: true,
          search: {
            regex: false,
            value: "",
          },
          searchValue: "",
          searchable: true,
        },
        {
          data: "contractorNotes",
          orderable: true,
          search: {
            regex: false,
            value: "",
          },
          searchValue: "",
          searchable: true,
        },
        {
          data: "entityStatus",
          orderable: true,
          search: {
            regex: false,
            value: "",
          },
          searchValue: params.status,
          searchable: true,
        },
        {
          data: "dataStatus",
          orderable: true,
          search: {
            regex: true,
            value: params.statusRegex || "",
          },
          searchValue: params.dataStatus || "",
          searchable: true,
        },
      ],
      search: {
        regex: false,
        value: params.query || "",
      },
      draw: params?.pageIndex ? params.pageIndex : 1,
      length: params?.recordLength ? params.recordLength : -1,
      start: 0,
    });

    return response?.data;
  }
);

export const getMasterDataEquipmentRomLogs = createAsyncThunk(
  "masterData/equipment-Rom/logs",
  async (params) => {
    const response = await post(`${apiUrls.masterData}/equipment-rom/logs`, {
      draw: params?.pageIndex ? params.pageIndex : 1,
      columns: [
        {
          data: "id",
          name: "",
          searchable: true,
          orderable: true,
          search: {
            value: "",
            regex: false,
          },
        },
      ],
      order: [
        {
          column: 0,
          dir: "desc",
        },
      ],
      start: 0,
      length: params?.recordLength ? params.recordLength : 10,
      search: {
        value: "",
        regex: false,
      },
    });
    return response?.data;
  }
);

export const getMasterDataEquipmentHaul = createAsyncThunk(
  "masterData/equipmentHaul",
  async (params) => {
    const response = await post(`${apiUrls.masterData}/equipment-haul/filter`, {
      columns: [
        {
          data: "id",
          orderable: true,
          search: {
            regex: false,
            value: "",
          },
          searchValue: "",
          searchable: true,
        },
        {
          data: "contractor",
          orderable: true,
          search: {
            regex: false,
            value: "",
          },
          searchValue: params.contractor || "",
          searchable: true,
        },
        {
          data: "equipment.type",
          orderable: true,
          search: {
            regex: false,
            value: "",
          },
          searchValue: params.type || "",
          searchable: true,
        },
        {
          data: "equipmentClass",
          orderable: true,
          search: {
            regex: false,
            value: "",
          },
          searchValue: params.class || "",
          searchable: true,
        },
        {
          data: "equipmentHaulId",
          orderable: true,
          search: {
            regex: false,
            value: "",
          },
          searchValue: params.equipmentId || "",
          searchable: true,
        },
        {
          data: "engineNumber",
          orderable: true,
          search: {
            regex: false,
            value: "",
          },
          searchValue: "",
          searchable: true,
        },
        {
          data: "chasisNumber",
          orderable: true,
          search: {
            regex: false,
            value: "",
          },
          searchValue: "",
          searchable: true,
        },
        {
          data: "brand",
          orderable: true,
          search: {
            regex: false,
            value: "",
          },
          searchValue: "",
          searchable: true,
        },
        {
          data: "axle",
          orderable: true,
          search: {
            regex: true,
            value: "",
          },
          searchValue: params.axle || "",
          searchable: true,
        },
        {
          data: "bibNotes",
          orderable: true,
          search: {
            regex: false,
            value: "",
          },
          searchValue: "",
          searchable: true,
        },
        {
          data: "contractorNotes",
          orderable: true,
          search: {
            regex: false,
            value: "",
          },
          searchValue: "",
          searchable: true,
        },
        {
          data: "entityStatus",
          orderable: true,
          search: {
            regex: false,
            value: "",
          },
          searchValue: params.status,
          searchable: true,
        },
        {
          data: "dataStatus",
          orderable: true,
          search: {
            regex: true,
            value: params.statusRegex || "",
          },
          searchValue: params.dataStatus || "",
          searchable: true,
        },
      ],
      search: {
        regex: false,
        value: params.query || "",
      },
      draw: params?.pageIndex ? params.pageIndex : 1,
      length: params?.recordLength ? params.recordLength : -1,
      start: 0,
    });

    return response?.data;
  }
);

export const getMasterDataEquipmentHaulConf = createAsyncThunk(
  "equipmentHaulConf/get",
  async (params) => {
    const response = await post(`${apiUrls.masterData}/equipment-haul/filter`, {
      columns: [
        {
          data: "entityStatus",
          orderable: true,
          search: {
            regex: false,
            value: "",
          },
          searchValue: 1,
          searchable: true,
        },
        {
          data: "dataStatus",
          orderable: true,
          search: {
            regex: true,
            value: "",
          },
          searchValue: 3,
          searchable: true,
        },
      ],
      draw: 1,
      length: -1,
      start: 0,
    });

    var filtered = response.data.list.map((v) => {
      return {
        equipmentType: v.equipment,
        ewh: 0,
        totalHours: 12,
        totalDelay: 0,
        bibNotes: v.bibNotes,
        delay: {
          breakdown: "0",
          fuel: "0",
          meal: "0",
          p2h: "0",
          p5m: "0",
        },
        shift: {
          pa: "0",
          ma: "0",
          ua: "0",
          eu: "0",
        },
      };
    });
    response.data.list = filtered;
    return response?.data;
  }
);

export const getMasterDataEquipmentHaulLogs = createAsyncThunk(
  "masterData/equipment-Haul/logs",
  async (params) => {
    const response = await post(`${apiUrls.masterData}/equipment-haul/logs`, {
      draw: params?.pageIndex ? params.pageIndex : 1,
      columns: [
        {
          data: "id",
          name: "",
          searchable: true,
          orderable: true,
          search: {
            value: "",
            regex: false,
          },
        },
      ],
      order: [
        {
          column: 0,
          dir: "desc",
        },
      ],
      start: 0,
      length: params?.recordLength ? params.recordLength : 10,
      search: {
        value: "",
        regex: false,
      },
    });
    return response?.data;
  }
);

export const getContractorCode = createAsyncThunk(
  "masterData/contractors-code",
  async (params) => {
    const response = await post(`${apiUrls.masterData}/contractors/filter`, {
      columns: [
        {
          data: "name",
          orderable: true,
          search: {
            regex: false,
            value: "",
          },
          searchValue: params.name,
          searchable: true,
        },
        {
          data: "code",
          orderable: true,
          search: {
            regex: false,
            value: "",
          },
          searchValue: params.code,
          searchable: true,
        },
        {
          data: "entityStatus",
          orderable: true,
          search: {
            regex: false,
            value: "",
          },
          searchValue: params.status,
          searchable: true,
        },
        {
          data: "dataStatus",
          orderable: true,
          search: {
            regex: true,
          },
          searchValue: params.dataStatus || params.statusRegex || "",
          searchable: true,
        },
      ],
      length: 1,
    });

    return response?.data;
  }
);

export const getMasterDataContractors = createAsyncThunk(
  "masterData/contractors",
  async (params) => {
    const response = await post(`${apiUrls.masterData}/contractors/filter`, {
      columns: [
        {
          data: "id",
          orderable: true,
          search: {
            regex: false,
            value: "",
          },
          searchValue: "",
          searchable: true,
        },
        {
          data: "name",
          orderable: true,
          search: {
            regex: false,
            value: "",
          },
          searchValue: params.name,
          searchable: true,
        },
        {
          data: "code",
          orderable: true,
          search: {
            regex: false,
            value: "",
          },
          searchValue: params.code,
          searchable: true,
        },
        {
          data: "bibNotes",
          orderable: true,
          search: {
            regex: false,
            value: "",
          },
          searchValue: "",
          searchable: true,
        },
        {
          data: "entityStatus",
          orderable: true,
          search: {
            regex: false,
            value: "",
          },
          searchValue: params.status,
          searchable: true,
        },
        {
          data: "dataStatus",
          orderable: true,
          search: {
            regex: true,
          },
          searchValue: params.dataStatus || params.statusRegex || "",
          searchable: true,
        },
      ],
      search: {
        regex: false,
        value: params.query || "",
      },
      draw: params?.pageIndex ? params.pageIndex : 1,
      length: params?.recordLength ? params.recordLength : -1,
      start: 0,
    });

    return response?.data;
  }
);

export const getMasterDataContractorLogs = createAsyncThunk(
  "masterData/contractors/logs",
  async (params) => {
    const response = await post(`${apiUrls.masterData}/contractors/logs`, {
      draw: params?.pageIndex ? params.pageIndex : 1,
      columns: [
        {
          data: "id",
          name: "",
          searchable: true,
          orderable: true,
          search: {
            value: "",
            regex: false,
          },
        },
      ],
      order: [
        {
          column: 0,
          dir: "desc",
        },
      ],
      start: 0,
      length: params?.recordLength ? params.recordLength : 10,
      search: {
        value: "",
        regex: false,
      },
    });
    return response?.data;
  }
);

export const uploadContractorLogo = createAsyncThunk(
  `masterData/contractors/uploadLogo`,
  async (param) => {
    const { id, file } = param;
    const response = await post(
      `${apiUrls.masterData}/contractors/${id}/logo`,
      file,
      {
        headers: { "Content-Type": undefined },
      }
    );
    return response.data;
  }
);

export const getMasterDataLocations = createAsyncThunk(
  "masterData/locations",
  async (params) => {
    const response = await get(`${apiUrls.masterData}/locations`);
    return response?.data;
  }
);

export const getMasterDataLoadingDumping = createAsyncThunk(
  "masterData/loading-dumping",
  async (params) => {
    const response = await post(`${apiUrls.masterData}/locations/filter`, {
      columns: [
        {
          data: "id",
          orderable: true,
          search: {
            regex: false,
            value: "",
          },
          searchValue: "",
          searchable: true,
        },
        {
          data: "area.name",
          orderable: true,
          search: {
            regex: !!params?.areaRegex,
          },
          searchValue: params.areaRegex || params.area || "",
          searchable: true,
        },
        {
          data: "name",
          orderable: true,
          search: {
            regex: false,
            value: "",
          },
          searchValue: params.name || "",
          searchable: true,
        },
        {
          data: "code",
          orderable: true,
          search: {
            regex: false,
            value: "",
          },
          searchValue: params.code || "",
          searchable: true,
        },
        {
          data: "bibNotes",
          orderable: true,
          search: {
            regex: false,
            value: "",
          },
          searchValue: "",
          searchable: true,
        },
        {
          data: "entityStatus",
          orderable: true,
          search: {
            regex: false,
            value: "",
          },
          searchValue: params.status || "",
          searchable: true,
        },
        {
          data: "dataStatus",
          orderable: true,
          search: {
            regex: true,
            value: params.statusRegex || "",
          },
          searchValue: params.dataStatus || "",
          searchable: true,
        },
      ],
      draw: params?.pageIndex ? params.pageIndex : 1,
      length: params?.recordLength ? params.recordLength : -1,
      search: {
        regex: false,
        value: params.query || "",
      },
      start: 0,
    });
    return response?.data;
  }
);

export const getMasterDataLoadingDumpingLogs = createAsyncThunk(
  "masterData/locations/logs",
  async (params) => {
    const response = await post(`${apiUrls.masterData}/locations/logs`, {
      draw: params?.pageIndex ? params.pageIndex : 1,
      columns: [
        {
          data: "id",
          name: "",
          searchable: true,
          orderable: true,
          search: {
            value: "",
            regex: false,
          },
        },
      ],
      order: [
        {
          column: 0,
          dir: "desc",
        },
      ],
      start: 0,
      length: params?.recordLength ? params.recordLength : 10,
      search: {
        value: "",
        regex: false,
      },
    });
    return response?.data;
  }
);

export const getMasterDataConfigurationContractor = createAsyncThunk(
  "masterData/configuration-contractors",
  async (params) => {
    const response = await post(
      `${apiUrls.masterData}/contractor-locations/filter`,
      {
        columns: [
          {
            data: "id",
            orderable: true,
            search: {
              regex: false,
              value: "",
            },
            searchValue: "",
            searchable: true,
          },
          {
            data: "location.name",
            orderable: true,
            search: {
              regex: false,
              value: "",
            },
            searchValue: params.location || "",
            searchable: true,
          },
          {
            data: "contractor.name",
            orderable: true,
            search: {
              regex: false,
              value: "",
            },
            searchValue: params.contractor || "",
            searchable: true,
          },
          {
            data: "bibNotes",
            orderable: true,
            search: {
              regex: false,
              value: "",
            },
            searchValue: "",
            searchable: true,
          },
          {
            data: "entityStatus",
            orderable: true,
            search: {
              regex: false,
              value: "",
            },
            searchValue: params.status || "",
            searchable: true,
          },
          {
            data: "dataStatus",
            orderable: true,
            search: {
              regex: true,
              value: params.statusRegex || "",
            },
            searchValue: params.dataStatus || "",
            searchable: true,
          },
          {
            data: "location.area.name",
            orderable: true,
            search: {
              regex: false,
              value: "",
            },
            searchValue: params.area,
            searchable: true,
          },
          {
            data: "contractor.code",
            orderable: true,
            search: {
              regex: false,
              value: "",
            },
            searchValue: params.code,
            searchable: true,
          },
        ],
        draw: params?.pageIndex ? params.pageIndex : 1,
        length: params?.recordLength ? params.recordLength : -1,
        search: {
          regex: false,
          value: params.query || "",
        },
        start: 0,
      }
    );
    return response?.data;
  }
);

export const getMasterDataConfigurationContractorLogs = createAsyncThunk(
  "masterData/configuration-contractor/logs",
  async (params) => {
    const response = await post(
      `${apiUrls.masterData}/contractor-locations/logs`,
      {
        draw: params?.pageIndex ? params.pageIndex : 1,
        columns: [
          {
            data: "id",
            name: "",
            searchable: true,
            orderable: true,
            search: {
              value: "",
              regex: false,
            },
          },
        ],
        order: [
          {
            column: 0,
            dir: "desc",
          },
        ],
        start: 0,
        length: params?.recordLength ? params.recordLength : 10,
        search: {
          value: "",
          regex: false,
        },
      }
    );
    return response?.data;
  }
);

export const getAllMasterDataLoadingDumping = createAsyncThunk(
  "masterData/loading-dumping-all",
  async (params) => {
    const response = await get(`${apiUrls.masterData}/locations`);
    return response?.data;
  }
);

export const getMasterDataEquipments = createAsyncThunk(
  "masterData/equipments",
  async (params) => {
    const response = await post(`${apiUrls.masterData}/equipments/filter`, {
      columns: [
        {
          data: "id",
          orderable: true,
          search: {
            regex: false,
            value: "",
          },
          searchValue: "",
          searchable: true,
        },
        {
          data: "contractorName",
          orderable: true,
          search: {
            regex: false,
            value: "",
          },
          searchValue: params.contractorName || "",
          searchable: true,
        },
        {
          data: "type",
          orderable: true,
          search: {
            regex: false,
            value: "",
          },
          searchValue: params.type || "",
          searchable: true,
        },
        {
          data: "equipmentClass",
          orderable: true,
          search: {
            regex: false,
            value: "",
          },
          searchValue: params.class || "",
          searchable: true,
        },
        {
          data: "entityStatus",
          orderable: true,
          search: {
            regex: false,
            value: "",
          },
          searchValue: params.status,
          searchable: true,
        },
        {
          data: "dataStatus",
          orderable: true,
          search: {
            regex: true,
            value: params.statusRegex || "",
          },
          searchValue: params.dataStatus || "",
          searchable: true,
        },
        {
          data: "bibNotes",
          orderable: true,
          search: {
            regex: false,
            value: "",
          },
          searchValue: "",
          searchable: true,
        },
        {
          data: "contractorCode",
          orderable: true,
          search: {
            regex: false,
            value: "",
          },
          searchValue: params.code,
          searchable: true,
        },
        {
          data: "brand",
          orderable: true,
          search: {
            regex: false,
            value: "",
          },
          searchValue: params.brand,
          searchable: true,
        },
      ],
      search: {
        regex: false,
        value: params.query || "",
      },
      draw: params?.pageIndex ? params.pageIndex : 1,
      length: params?.recordLength ? params.recordLength : -1,
    });
    return response?.data;
  }
);

export const getMasterDataEquipmentLogs = createAsyncThunk(
  "masterData/equipments/logs",
  async (params) => {
    const response = await post(`${apiUrls.masterData}/equipments/logs`, {
      draw: params?.pageIndex ? params.pageIndex : 1,
      columns: [
        {
          data: "id",
          name: "",
          searchable: true,
          orderable: true,
          search: {
            value: "",
            regex: false,
          },
        },
      ],
      order: [
        {
          column: 0,
          dir: "desc",
        },
      ],
      start: 0,
      length: params?.recordLength ? params.recordLength : 10,
      search: {
        value: "",
        regex: false,
      },
    });
    return response?.data;
  }
);

export const getMasterDataEquipmentsAll = createAsyncThunk(
  "masterData/equipmentsAll",
  async (params) => {
    const response = await get(`${apiUrls.masterData}/equipments`);
    return response?.data;
  }
);

export const getMasterDataSublocations = createAsyncThunk(
  "masterData/sublocations",
  async (params) => {
    const response = await post(`${apiUrls.masterData}/sublocations/filter`, {
      columns: [
        {
          data: "id",
          orderable: true,
          search: {
            regex: false,
            value: "",
          },
          searchValue: "",
          searchable: true,
        },
        {
          data: "location.area.name",
          orderable: true,
          search: {
            regex: false,
            value: "",
          },
          searchValue: params.area,
          searchable: true,
        },
        {
          data: "location.name",
          orderable: true,
          search: {
            regex: false,
            value: "",
          },
          searchValue: params.location,
          searchable: true,
        },
        {
          data: "name",
          orderable: true,
          search: {
            regex: false,
            value: "",
          },
          searchValue: params.name,
          searchable: true,
        },
        {
          data: "entityStatus",
          orderable: true,
          search: {
            regex: false,
            value: "",
          },
          searchValue: params.status || "",
          searchable: true,
        },
        {
          data: "dataStatus",
          orderable: true,
          search: {
            regex: true,
            value: params.statusRegex || "",
          },
          searchValue: params.dataStatus || "",
          searchable: true,
        },
        {
          data: "bibNotes",
          orderable: true,
          search: {
            regex: false,
            value: "",
          },
          searchValue: "",
          searchable: true,
        },
        {
          data: "location.code",
          orderable: true,
          search: {
            regex: false,
            value: "",
          },
          searchValue: params.locationCode,
          searchable: true,
        },
      ],
      draw: params?.pageIndex ? params.pageIndex : 1,
      length: params?.recordLength ? params.recordLength : -1,
      search: {
        regex: false,
        value: params.query || "",
      },
      start: 0,
    });
    return response?.data;
  }
);

export const getMasterDataSublocationsDumping = createAsyncThunk(
  "masterData/sublocationsDumping",
  async (params) => {
    const response = await post(`${apiUrls.masterData}/sublocations/filter`, {
      columns: [
        {
          data: "id",
          orderable: true,
          search: {
            regex: false,
            value: "",
          },
          searchValue: "",
          searchable: true,
        },
        {
          data: "location.area.name",
          orderable: true,
          search: {
            regex: false,
            value: "",
          },
          searchValue: params.area,
          searchable: true,
        },
        {
          data: "location.name",
          orderable: true,
          search: {
            regex: false,
            value: "",
          },
          searchValue: params.location,
          searchable: true,
        },
        {
          data: "name",
          orderable: true,
          search: {
            regex: false,
            value: "",
          },
          searchValue: params.name,
          searchable: true,
        },
        {
          data: "entityStatus",
          orderable: true,
          search: {
            regex: false,
            value: "",
          },
          searchValue: params.status || "",
          searchable: true,
        },
        {
          data: "dataStatus",
          orderable: true,
          search: {
            regex: true,
            value: params.statusRegex || "",
          },
          searchValue: params.dataStatus || "",
          searchable: true,
        },
        {
          data: "bibNotes",
          orderable: true,
          search: {
            regex: false,
            value: "",
          },
          searchValue: "",
          searchable: true,
        },
      ],
      draw: 1,
      length: params?.recordLength ? params.recordLength : -1,
      search: {
        regex: false,
        value: params.query || "",
      },
      start: 0,
    });
    return response?.data;
  }
);

export const getMasterDataSublocationLogs = createAsyncThunk(
  "masterData/sublocations/logs",
  async (params) => {
    const response = await post(`${apiUrls.masterData}/sublocations/logs`, {
      draw: params?.pageIndex ? params.pageIndex : 1,
      columns: [
        {
          data: "id",
          name: "",
          searchable: true,
          orderable: true,
          search: {
            value: "",
            regex: false,
          },
        },
      ],
      order: [
        {
          column: 0,
          dir: "desc",
        },
      ],
      start: 0,
      length: params?.recordLength ? params.recordLength : 10,
      search: {
        value: "",
        regex: false,
      },
    });
    return response?.data;
  }
);

export const getMasterDataElevations = createAsyncThunk(
  "masterData/elevation",
  async (params) => {
    const response = await get(`${apiUrls.masterData}/elevations`);
    return response?.data;
  }
);

export const getMasterDataLocationsByContractorName = createAsyncThunk(
  "masterData/masterDataLocationsByContractorName",
  async (params) => {
    const { name } = params;
    const response = await get(
      `${apiUrls.masterData}/contractors/names/${name}/locations`
    );
    return response?.data;
  }
);

export const getPitList = createAsyncThunk(
  "waste-removal/pit-list",
  async (params) => {
    const responseLocation = await post(
      `${apiUrls.masterData}/locations/filter`,
      {
        columns: [
          {
            data: "area.name",
            orderable: true,
            search: {
              regex: false,
              value: "",
            },
            searchValue: "Pit",
            searchable: true,
          },
          {
            data: "entityStatus",
            orderable: true,
            search: {
              regex: false,
              value: "",
            },
            searchValue: 1,
            searchable: true,
          },
          {
            data: "dataStatus",
            orderable: true,
            search: {
              regex: false,
              value: "",
            },
            searchValue: 3,
            searchable: true,
          },
        ],
        length: -1,
        start: 0,
      }
    );

    const responseContractor = await post(
      `${apiUrls.masterData}/contractor-locations/filter`,
      {
        columns: [
          {
            data: "contractor.name",
            orderable: true,
            search: {
              regex: false,
              value: "",
            },
            searchValue: params.contractor,
            searchable: true,
          },
          {
            data: "entityStatus",
            orderable: true,
            search: {
              regex: false,
              value: "",
            },
            searchValue: 1,
            searchable: true,
          },
          {
            data: "dataStatus",
            orderable: true,
            search: {
              regex: false,
              value: "",
            },
            searchValue: 3,
            searchable: true,
          },
        ],
        length: -1,
        start: 0,
      }
    );

    const pitList = responseLocation?.data?.list || [];
    const contractorList = responseContractor?.data?.list || [];
    let contractorPitList = [];

    pitList.map((e) => {
      contractorList.map((val) => {
        if (e.id === val.location.id)
          return contractorPitList.push(val.location);
      });
    });

    return contractorPitList;
  }
);

export const getMasterDataAreaName = createAsyncThunk(
  "masterData/area/allNames",
  async (params) => {
    const response = await get(`${apiUrls.masterData}/areas/names`);
    return response?.data;
  }
);

export const getMasterDataLocationName = createAsyncThunk(
  "masterData/location/allNames",
  async (params) => {
    const response = await get(`${apiUrls.masterData}/locations/names`);
    return response?.data;
  }
);

export const getMasterDataLocationCode = createAsyncThunk(
  "masterData/location/allCodes",
  async (params) => {
    const response = await get(`${apiUrls.masterData}/locations/codes`);
    return response?.data;
  }
);

export const getMasterDataSublocationName = createAsyncThunk(
  "masterData/sublocation/allNames",
  async (params) => {
    const response = await get(`${apiUrls.masterData}/sublocations/names`);
    return response?.data;
  }
);

export const getMasterDataContractorName = createAsyncThunk(
  "masterData/contractor/allNames",
  async (params) => {
    const response = await get(`${apiUrls.masterData}/contractors/names`);
    return response?.data;
  }
);

export const getMasterDataContractorCode = createAsyncThunk(
  "masterData/contractor/allCodes",
  async (params) => {
    const response = await get(`${apiUrls.masterData}/contractors/codes`);
    return response?.data;
  }
);

export const getMasterDataEquipmentType = createAsyncThunk(
  "masterData/equipments/allType",
  async (params) => {
    const response = await get(`${apiUrls.masterData}/equipments/types`);
    return response?.data;
  }
);

export const getMasterDataEquipmentClass = createAsyncThunk(
  "masterData/equipments/allClass",
  async (params) => {
    const response = await get(
      `${apiUrls.masterData}/equipments/equipment-classes`
    );
    return response?.data;
  }
);

export const getMasterDataEquipmentBrand = createAsyncThunk(
  "masterData/equipments/allBrands",
  async (params) => {
    const response = await get(
      `${apiUrls.masterData}/equipments/brands`
    );
    return response?.data;
  }
);

export const getMasterDataEquipmentRomJob = createAsyncThunk(
  "masterData/equipments-rom/allJobs",
  async (params) => {
    const response = await get(`${apiUrls.masterData}/equipment-rom/jobs`);
    return response?.data;
  }
);

export const getMasterDataEquipmentHaulAxle = createAsyncThunk(
  "masterData/equipments-haul/allAxle",
  async (params) => {
    const response = await get(`${apiUrls.masterData}/equipment-haul/axle`);
    return response?.data;
  }
);

export const getMasterDataCrusherDesignCapacity = createAsyncThunk(
  "masterData/crusher/alldesignCapacity",
  async (params) => {
    const response = await get(`${apiUrls.mdPortCrusher}/design-capacities`);
    return response?.data;
  }
);

export const getMasterDataRomPortDesignCapacity = createAsyncThunk(
  "masterData/RomPort/alldesignCapacity",
  async (params) => {
    const response = await get(`${apiUrls.mdRom}/design-capacities`);
    return response?.data;
  }
);

export const getMasterDataJettyDesignCapacity = createAsyncThunk(
  "masterData/jetty/alldesignCapacity",
  async (params) => {
    const response = await get(`${apiUrls.mdPortJetty}/design-capacities`);
    return response?.data;
  }
);

/*
  get list contractor & location by area name
  example filter: 'ROM|PIT'
*/
export const getOptionsContractorLocation = createAsyncThunk(
  "master/contractorLocation",
  async (filter) => {
    const locationFilterHeader = [
      {
        data: "area.name",
        name: "area.name",
        search: {
          regex: true,
          value: filter,
        },
        searchable: true,
        orderable: true,
      },
      {
        data: "dataStatus",
        orderable: true,
        search: {
          regex: false,
          value: "",
        },
        searchValue: 3,
        searchable: true,
      },
    ];

    const locationFilter = [
      {
        data: "area.name",
        name: "area.name",
        search: {
          regex: true,
          value: filter == "ROM" ? filter : "Haul Road",
        },
        searchable: true,
        orderable: true,
      },
      {
        data: "dataStatus",
        orderable: true,
        search: {
          regex: false,
          value: "",
        },
        searchValue: 3,
        searchable: true,
      },
    ];

    const locationsHeader = await post(
      getAPIEndpoint("master-data", "v1", "/locations/filter"),
      {
        columns: locationFilterHeader,
        length: -1,
        start: 0,
      }
    );

    const locations = await post(
      getAPIEndpoint("master-data", "v1", "/locations/filter"),
      {
        columns: locationFilter,
        length: -1,
        start: 0,
      }
    );

    const locationContractorFilter = [
      {
        data: "location.name",
        name: "location.name",
        search: {
          regex: true,
          value: locations.data.list.map((item) => item.name).join("|"),
        },
        searchable: true,
        orderable: true,
      },
      {
        data: "dataStatus",
        name: "dataStatus",
        search: {
          regex: false,
          value: 3,
        },
        searchable: true,
        orderable: true,
      },
    ];
    const locationContractors = await post(
      getAPIEndpoint("master-data", "v1", "/contractor-locations/filter"),
      {
        columns: locationContractorFilter,
        length: -1,
        start: 0,
      }
    );

    const listContractor = [];
    locationContractors.data.list.forEach((item) => {
      const isExist = listContractor.findIndex(
        (x) =>
          x.label === item.contractor.name && x.value === item.contractor.id
      );

      if (isExist === -1) {
        listContractor.push({
          label: item.contractor.name,
          value: item.contractor.id,
        });
      }
    });
    const listLocations = locationsHeader.data.list.map((item) => {
      return {
        label: item.name,
        value: item.id,
        code: item.code,
      };
    });

    return {
      contractors: listContractor,
      locations: listLocations,
    };
  }
);

export const getMasterDataLocationByContractorName = createAsyncThunk(
  "masterData/contractor-location",
  async (params) => {
    const response = await get(
      `${apiUrls.masterData}/contractors/names/${params.contractor}/locations?areaName=${params.area}`
    );
    return response?.data;
  }
);

export const fetchContractorByArea = createAsyncThunk(
  `master-data/area-by-name`,
  async (params) => {
    const { area } = params;
    const response = await get(`${apiUrls.masterData}/areas/names/${area}/contractors`);
    return response?.data;
  }
);

const masterDataSlice = createSlice({
  name: "MASTER_DATA",
  initialState,
  reducers: {
    /*
    CONFIGURATION CONTRACTOR
    */
    /**
     * * ADD DATA TABLE CONFIGURATION CONTRACTOR *
     * Todo: to add table on master data configuration contractor page
     * @param action
     */
    addDataTableConfigurationContractor: (state, action) => {
      const payload = action.payload;

      state.configurationContractor?.records.unshift(payload);
    },

    /**
     * * REMOVE FIRST DATA TABLE CONFIGURATION CONTRACTOR *
     * Todo: to remove first row on table master data configuration contractor page
     * @param action
     */
    removeFirstDataTableConfigurationContractor: (state, action) => {
      state.configurationContractor?.records.shift();
    },

    /**
     * * REMOVE DATA TABLE CONFIGURATION CONTRACTOR *
     * Todo: to remove row on table master data configuration contractor page
     * @param action
     */
    onRemoveDataTableConfigurationContractor: (state, action) => {
      const payload = action.payload;

      state.configurationContractor.records.splice(payload.tableIndexRow, 1);
    },

    /**
     * * ONCHANGE TABLE CONFIGURATION CONTRACTOR *
     * Todo: to change table on master data configuration contractor page
     * @param action
     */
    onChangeTableConfigurationContractor: (state, action) => {
      const payload = action.payload;

      if (payload.tableRowName === "location") {
        state.configurationContractor.records[payload.tableIndexRow][
          "location"
        ] = payload.value;
      } else if (payload.tableRowName === "contractor") {
        state.configurationContractor.records[payload.tableIndexRow][
          "contractor"
        ] = payload.value;
      } else if (payload.tableRowName === "area") {
        state.configurationContractor.records[payload.tableIndexRow][
          payload.tableRowName
        ] = payload.value;
        state.configurationContractor.records[payload.tableIndexRow][
          "location"
        ] = {};
      } else {
        state.configurationContractor.records[payload.tableIndexRow][
          payload.tableRowName
        ] = payload.value;
      }
    },

    /**
     * * ONREVERT TABLE CONFIGURATION CONTRACTOR *
     * Todo: to change table on master data configuration contractor page
     * @param action
     */
    onRevertTableConfigurationContractor: (state, action) => {
      const payload = action.payload;

      state.configurationContractor.revert =
        state.configurationContractor.records;
      if (!payload) state.configurationContractor.onRevert = true;
      else state.configurationContractor.onRevert = false;
    },

    /**
     * * ONREVERT TABLE CONFIGURATION CONTRACTOR *
     * Todo: to change table on master data configuration contractor page
     * @param action
     */
    onRevertChangeTableConfigurationContractor: (state, action) => {
      state.configurationContractor.records =
        state.configurationContractor.revert;

      state.configurationContractor.revert = [];
      state.configurationContractor.onRevert = false;
    },

    /*
    AREA
    */
    /**
     * * ADD DATA TABLE AREA *
     * Todo: to add table on master data area page
     * @param action
     */
    addDataTableArea: (state, action) => {
      const payload = action.payload;

      state.areas?.records.unshift(payload);
    },

    /**
     * * REMOVE DATA TABLE AREA *
     * Todo: to remove row on table master data area page
     * @param action
     */
    onRemoveDataTableArea: (state, action) => {
      const payload = action.payload;

      state.areas.records.splice(payload.tableIndexRow, 1);
    },

    /**
     * * ONCHANGE TABLE AREA *
     * Todo: to change table on master data area page
     * @param action
     */
    onChangeTableArea: (state, action) => {
      const payload = action.payload;

      state.areas.records[payload.tableIndexRow][payload.tableRowName] =
        payload.value;
    },

    /**
     * * ONREVERT TABLE AREA *
     * Todo: to change table on master data area page
     * @param action
     */
    onRevertTableArea: (state, action) => {
      const payload = action.payload;

      state.areas.revert = state.areas.records;
      if (!payload) state.areas.onRevert = true;
      else state.areas.onRevert = false;
    },

    /*
    CRUSHER
    */
    /**
     * * ADD DATA TABLE CRUSHER *
     * Todo: to add table on master data crusher page
     * @param action
     */
    addDataTableCrusher: (state, action) => {
      const payload = action.payload;

      state.crusher?.records.unshift(payload);
    },

    /*
    ROM Port
    */
    /**
     * * ADD DATA TABLE PORT *
     * Todo: to add table on master data rom port page
     * @param action
     */
    addDataTableRomPort: (state, action) => {
      const payload = action.payload;

      state.RomPort?.records.unshift(payload);
    },

    /**
     * * REMOVE DATA TABLE ROM PORT *
     * Todo: to remove row on table master data rom port page
     * @param action
     */
    onRemoveDataTableRomPort: (state, action) => {
      const payload = action.payload;

      state.RomPort.records.splice(payload.tableIndexRow, 1);
    },

    /**
    * * ONCHANGE TABLE ROM PORT *
    * Todo: to change table on master data rom port page
    * @param action
    */
    onChangeTableRomPort: (state, action) => {
      const payload = action.payload;

      state.RomPort.records[payload.tableIndexRow][payload.tableRowName] =
        payload.value;
    },

    /**
     * * ONREVERT TABLE ROM PORT *
     * Todo: to change table on master data rom port page
     * @param action
     */
    onRevertTableRomPort: (state, action) => {
      const payload = action.payload;

      state.RomPort.revert = state.RomPort.records;
      if (!payload) state.RomPort.onRevert = true;
      else state.RomPort.onRevert = false;
    },

    /**
     * * REMOVE FIRST DATA TABLE CRUSHER *
     * Todo: to remove first row on table master data crusher page
     * @param action
     */
    removeFirstDataTableCrusher: (state, action) => {
      state.crusher?.records.shift();
    },

    /**
     * * REMOVE DATA TABLE CRUSHER *
     * Todo: to remove row on table master data crusher page
     * @param action
     */
    onRemoveDataTableCrusher: (state, action) => {
      const payload = action.payload;

      state.crusher.records.splice(payload.tableIndexRow, 1);
    },

    /**
     * * ONCHANGE TABLE CRUSHER *
     * Todo: to change table on master data crusher page
     * @param action
     */
    onChangeTableCrusher: (state, action) => {
      const payload = action.payload;

      state.crusher.records[payload.tableIndexRow][payload.tableRowName] =
        payload.value;
    },

    /**
     * * ONREVERT TABLE CRUSHER *
     * Todo: to change table on master data crusher page
     * @param action
     */
    onRevertTableCrusher: (state, action) => {
      const payload = action.payload;

      state.crusher.revert = state.crusher.records;
      if (!payload) state.crusher.onRevert = true;
      else state.crusher.onRevert = false;
    },

    /**
     * * ONREVERT TABLE CRUSHER *
     * Todo: to change table on master data crusher page
     * @param action
     */
    onRevertChangeTableCrusher: (state, action) => {
      state.crusher.records = state.crusher.revert;

      state.crusher.revert = [];
      state.crusher.onRevert = false;
    },

    /*
    JETTY
    */
    /**
     * * ADD DATA TABLE JETTY *
     * Todo: to add table on master data jetty page
     * @param action
     */
    addDataTableJetty: (state, action) => {
      const payload = action.payload;

      state.jetty?.records.unshift(payload);
    },

    onChangeTableInput: (state, action) => {
      const payload = action.payload;
      _.set(
        state.jettyconf.records[payload.tableIndexRow],
        payload.tableRowName,
        parseInt(payload.value)
      );
    },

    onChangeEwhConfInput: (state, action) => {
      const payload = action.payload;
      if (!isNumeric(payload.value)) {
        payload.value = "0";
      }
      var arrStr = payload.value.toString().split("");
      if (arrStr.length >= 2) {
        if (arrStr[1] != "." && arrStr[0] == "0") {
          payload.value = payload.value.toString().split("").splice(1).join("");
        }
      }

      let totalDelay = 0;
      totalDelay +=
        payload.tableRowName == "delay.breakdown"
          ? Number(payload.value)
          : Number(
            state.equipmentHaulConf.records[payload.tableIndexRow]["delay"][
            "breakdown"
            ]
          );
      totalDelay +=
        payload.tableRowName == "delay.fuel"
          ? Number(payload.value)
          : Number(
            state.equipmentHaulConf.records[payload.tableIndexRow]["delay"][
            "fuel"
            ]
          );
      totalDelay +=
        payload.tableRowName == "delay.meal"
          ? Number(payload.value)
          : Number(
            state.equipmentHaulConf.records[payload.tableIndexRow]["delay"][
            "meal"
            ]
          );
      totalDelay +=
        payload.tableRowName == "delay.p2h"
          ? Number(payload.value)
          : Number(
            state.equipmentHaulConf.records[payload.tableIndexRow]["delay"][
            "p2h"
            ]
          );
      totalDelay +=
        payload.tableRowName == "delay.p5m"
          ? Number(payload.value)
          : Number(
            state.equipmentHaulConf.records[payload.tableIndexRow]["delay"][
            "p5m"
            ]
          );

      var ewh =
        state.equipmentHaulConf.records[payload.tableIndexRow]["totalHours"] -
        Number(totalDelay);
      var sumEWhWithoutBreakdown = 0;
      sumEWhWithoutBreakdown +=
        payload.tableRowName == "delay.fuel"
          ? Number(payload.value)
          : Number(
            state.equipmentHaulConf.records[payload.tableIndexRow]["delay"][
            "fuel"
            ]
          );
      sumEWhWithoutBreakdown +=
        payload.tableRowName == "delay.meal"
          ? Number(payload.value)
          : Number(
            state.equipmentHaulConf.records[payload.tableIndexRow]["delay"][
            "meal"
            ]
          );
      sumEWhWithoutBreakdown +=
        payload.tableRowName == "delay.p2h"
          ? Number(payload.value)
          : Number(
            state.equipmentHaulConf.records[payload.tableIndexRow]["delay"][
            "p2h"
            ]
          );
      sumEWhWithoutBreakdown +=
        payload.tableRowName == "delay.p5m"
          ? Number(payload.value)
          : Number(
            state.equipmentHaulConf.records[payload.tableIndexRow]["delay"][
            "p5m"
            ]
          );
      sumEWhWithoutBreakdown += ewh;

      var sumEwhBreakdown = 0;
      sumEwhBreakdown +=
        payload.tableRowName == "delay.breakdown"
          ? Number(payload.value)
          : Number(
            state.equipmentHaulConf.records[payload.tableIndexRow]["delay"][
            "breakdown"
            ]
          );
      sumEwhBreakdown += ewh;
      var pa = parseFloat(sumEWhWithoutBreakdown / (ewh + totalDelay)).toFixed(
        2
      );
      var ma = parseFloat(ewh / sumEwhBreakdown).toFixed(2);
      var ua = parseFloat(ewh / sumEWhWithoutBreakdown).toFixed(2);
      var eu = parseFloat(ewh / (ewh + totalDelay)).toFixed(2);

      if (totalDelay <= 12) {
        _.set(
          state.equipmentHaulConf.records[payload.tableIndexRow],
          payload.tableRowName,
          payload.value
        );
        _.set(
          state.equipmentHaulConf.records[payload.tableIndexRow],
          "totalDelay",
          parseFloat(totalDelay).toFixed(2)
        );
        _.set(
          state.equipmentHaulConf.records[payload.tableIndexRow],
          "ewh",
          ewh
        );
        _.set(
          state.equipmentHaulConf.records[payload.tableIndexRow],
          "shift.pa",
          pa
        );
        _.set(
          state.equipmentHaulConf.records[payload.tableIndexRow],
          "shift.ma",
          ma
        );
        _.set(
          state.equipmentHaulConf.records[payload.tableIndexRow],
          "shift.ua",
          ua
        );
        _.set(
          state.equipmentHaulConf.records[payload.tableIndexRow],
          "shift.eu",
          eu
        );
      }
    },

    /**
     * * REMOVE FIRST DATA TABLE JETTY *
     * Todo: to remove first row on table master data jetty page
     * @param action
     */
    removeFirstDataTableJetty: (state, action) => {
      state.jetty?.records.shift();
    },

    /**
     * * REMOVE DATA TABLE JETTY *
     * Todo: to remove row on table master data jetty page
     * @param action
     */
    onRemoveDataTableJetty: (state, action) => {
      const payload = action.payload;

      state.jetty.records.splice(payload.tableIndexRow, 1);
    },

    /**
     * * ONCHANGE TABLE JETTY *
     * Todo: to change table on master data jetty page
     * @param action
     */
    onChangeTableJetty: (state, action) => {
      const payload = action.payload;

      state.jetty.records[payload.tableIndexRow][payload.tableRowName] =
        payload.value;
    },

    /**
     * * ONREVERT TABLE JETTY *
     * Todo: to change table on master data jetty page
     * @param action
     */
    onRevertTableJetty: (state, action) => {
      const payload = action.payload;

      state.jetty.revert = state.jetty.records;
      if (!payload) state.jetty.onRevert = true;
      else state.jetty.onRevert = false;
    },

    /**
     * * ONREVERT TABLE JETTY *
     * Todo: to change table on master data jetty page
     * @param action
     */
    onRevertChangeTableJetty: (state, action) => {
      state.jetty.records = state.jetty.revert;

      state.jetty.revert = [];
      state.jetty.onRevert = false;
    },

    /*
      Location
    */
    /**
     * * ADD DATA TABLE LOCATION *
     * Todo: to add table on master data location page
     * @param action
     */
    addDataTableLocation: (state, action) => {
      const payload = action.payload;

      state.loadingDumping?.records.unshift(payload);
    },

    /**
     * * REMOVE DATA TABLE LOCATION *
     * Todo: to remove row on table master data location page
     * @param action
     */
    onRemoveDataTableLocation: (state, action) => {
      const payload = action.payload;

      state.loadingDumping.records.splice(payload.tableIndexRow, 1);
    },

    /**
     * * ONCHANGE TABLE LOCATION *
     * Todo: to change table on master data location page
     * @param action
     */
    onChangeTableLocation: (state, action) => {
      const payload = action.payload;

      state.loadingDumping.records[payload.tableIndexRow][
        payload.tableRowName
      ] = payload.value;
    },

    /**
     * * ONREVERT TABLE LOCATION *
     * Todo: to change table on master data location page
     * @param action
     */
    onRevertTableLocation: (state, action) => {
      const payload = action.payload;

      state.loadingDumping.revert = state.loadingDumping.records;
      if (!payload) state.loadingDumping.onRevert = true;
      else state.loadingDumping.onRevert = false;
    },

    /*
      Contractor
    */
    /**
     * * ADD DATA TABLE CONTRACTOR *
     * Todo: to add table on master data contractor page
     * @param action
     */
    addDataTableContractor: (state, action) => {
      const payload = action.payload;

      state.contractors?.records.unshift(payload);
    },

    /**
     * * REMOVE DATA TABLE CONTRACTOR *
     * Todo: to remove row on table master data contractor page
     * @param action
     */
    onRemoveDataTableContractor: (state, action) => {
      const payload = action.payload;

      state.contractors.records.splice(payload.tableIndexRow, 1);
    },

    /**
     * * ONCHANGE TABLE CONTRACTOR *
     * Todo: to change table on master data contractor page
     * @param action
     */
    onChangeTableContractor: (state, action) => {
      const payload = action.payload;

      state.contractors.records[payload.tableIndexRow][payload.tableRowName] =
        payload.value;
      state.contractors.records[payload.tableIndexRow] = {
        ...state.contractors.records[payload.tableIndexRow],
        hasChange: true,
      };

      if (payload.tableRowName === "logo") {
        state.contractors.records[payload.tableIndexRow]['isNewLogo'] = true;
      }
    },

    /**
     * * ONREVERT TABLE CONTRACTOR *
     * Todo: to change table on master data contractor page
     * @param action
     */
    onRevertTableContractor: (state, action) => {
      const payload = action.payload;

      state.contractors.revert = state.contractors.records;
      if (!payload) state.contractors.onRevert = true;
      else state.contractors.onRevert = false;
    },

    /*
      SubLocation
    */
    /**
     * * ADD DATA TABLE SUBLOCATION *
     * Todo: to add table on master data sublocation page
     * @param action
     */
    addDataTableSubLocation: (state, action) => {
      const payload = action.payload;

      state.subLocations?.records.unshift(payload);
    },

    /**
     * * REMOVE DATA TABLE SUBLOCATION *
     * Todo: to remove row on table master data sublocation page
     * @param action
     */
    onRemoveDataTableSubLocation: (state, action) => {
      const payload = action.payload;

      state.subLocations.records.splice(payload.tableIndexRow, 1);
    },

    /**
     * * ONCHANGE TABLE SUBLOCATION *
     * Todo: to change table on master data sublocation page
     * @param action
     */
    onChangeTableSubLocation: (state, action) => {
      const payload = action.payload;

      if (payload.tableRowName == "area") {
        state.subLocations.records[payload.tableIndexRow]["location"] = {};
        state.subLocations.records[payload.tableIndexRow]["location"][
          payload.tableRowName
        ] = payload.value;
      } else
        state.subLocations.records[payload.tableIndexRow][
          payload.tableRowName
        ] = payload.value;
    },

    /**
     * * ONREVERT TABLE SUBLOCATION *
     * Todo: to change table on master data sublocation page
     * @param action
     */
    onRevertTableSubLocation: (state, action) => {
      const payload = action.payload;

      state.subLocations.revert = state.subLocations.records;
      if (!payload) state.subLocations.onRevert = true;
      else state.subLocations.onRevert = false;
    },

    /*
      Equipment
    */
    /**
     * * ADD DATA TABLE EQUIPMENT *
     * Todo: to add table on master data equipment page
     * @param action
     */
    addDataTableEquipment: (state, action) => {
      const payload = action.payload;

      state.equipments?.records.unshift(payload);
    },

    /**
     * * REMOVE DATA TABLE EQUIPMENT *
     * Todo: to remove row on table master data equipment page
     * @param action
     */
    onRemoveDataTableEquipment: (state, action) => {
      const payload = action.payload;

      state.equipments.records.splice(payload.tableIndexRow, 1);
    },

    /**
     * * ONCHANGE TABLE EQUIPMENT *
     * Todo: to change table on master data equipment page
     * @param action
     */
    onChangeTableEquipment: (state, action) => {
      const payload = action.payload;
      if (payload.tableRowName == 'contractorName') {
        state.equipments.records[payload.tableIndexRow][payload.tableRowName] = payload.value?.name;
        state.equipments.records[payload.tableIndexRow]['contractorCode'] = payload.value?.code;
      } else {
        state.equipments.records[payload.tableIndexRow][payload.tableRowName] =
          payload.value;
      }
    },

    /**
     * * ONREVERT TABLE EQUIPMENT *
     * Todo: to change table on master data equipment page
     * @param action
     */
    onRevertTableEquipment: (state, action) => {
      const payload = action.payload;

      state.equipments.revert = state.equipments.records;
      if (!payload) state.equipments.onRevert = true;
      else state.equipments.onRevert = false;
    },

    /*
      Material
    */
    /**
     * * ADD DATA TABLE MATERIAL *
     * Todo: to add table on master data material page
     * @param action
     */
    addDataTableMaterial: (state, action) => {
      const payload = action.payload;

      state.materials?.records.unshift(payload);
    },

    /**
     * * REMOVE DATA TABLE MATERIAL *
     * Todo: to remove row on table master data material page
     * @param action
     */
    onRemoveDataTableMaterial: (state, action) => {
      const payload = action.payload;

      state.materials.records.splice(payload.tableIndexRow, 1);
    },

    /**
     * * ONCHANGE TABLE MATERIAL *
     * Todo: to change table on master data material page
     * @param action
     */
    onChangeTableMaterial: (state, action) => {
      const payload = action.payload;

      if (payload.tableRowName == "type")
        state.materials.records[payload.tableIndexRow]["name"] = "";
      state.materials.records[payload.tableIndexRow][payload.tableRowName] =
        payload.value;
    },

    /**
     * * ONREVERT TABLE MATERIAL *
     * Todo: to change table on master data material page
     * @param action
     */
    onRevertTableMaterial: (state, action) => {
      const payload = action.payload;

      state.materials.revert = state.materials.records;
      if (!payload) state.materials.onRevert = true;
      else state.materials.onRevert = false;
    },

    /*
      EquipmentDetail
    */
    /**
     * * ADD DATA TABLE EQUIPMENT DETAIL *
     * Todo: to add table on master data equipmentDetail page
     * @param action
     */
    addDataTableEquipmentDetails: (state, action) => {
      const payload = action.payload;

      state.equipmentDetails?.records.unshift(payload);
    },

    /**
     * * REMOVE DATA TABLE EQUIPMENT DETAIL *
     * Todo: to remove row on table master data equipmentDetail page
     * @param action
     */
    onRemoveDataTableEquipmentDetails: (state, action) => {
      const payload = action.payload;

      state.equipmentDetails.records.splice(payload.tableIndexRow, 1);
    },

    /**
     * * ONCHANGE TABLE EQUIPMENT DETAIL *
     * Todo: to change table on master data equipmentDetail page
     * @param action
     */
    onChangeTableEquipmentDetails: (state, action) => {
      const payload = action.payload;

      if (payload.tableRowName == "equipment.contractorName") {
        state.equipmentDetails.records[payload.tableIndexRow]["equipment"][
          "type"
        ] = "";
        state.equipmentDetails.records[payload.tableIndexRow]["equipment"][
          "equipmentClass"
        ] = "";
        state.equipmentDetails.records[payload.tableIndexRow]["equipment"][
          "contractorName"
        ] = payload.value;
        state.equipmentDetails.records[payload.tableIndexRow][
          "contractorName"
        ] = payload.value;
        state.equipmentDetails.records[payload.tableIndexRow]["brand"] = "";
      } else if (payload.tableRowName == "equipment.type") {
        state.equipmentDetails.records[payload.tableIndexRow]["equipment"][
          "id"
        ] = payload.id;
        state.equipmentDetails.records[payload.tableIndexRow]["equipment"][
          "type"
        ] = payload.value;
        state.equipmentDetails.records[payload.tableIndexRow]["type"] =
          payload.value;
        state.equipmentDetails.records[payload.tableIndexRow]["equipment"][
          "equipmentClass"
        ] = "";
        state.equipmentDetails.records[payload.tableIndexRow]["brand"] = "";
      } else if (payload.tableRowName == "equipment.equipmentClass") {
        state.equipmentDetails.records[payload.tableIndexRow]["equipment"][
          "id"
        ] = payload.id;
        state.equipmentDetails.records[payload.tableIndexRow]["equipment"][
          "equipmentClass"
        ] = payload.value;
        state.equipmentDetails.records[payload.tableIndexRow][
          "equipmentClass"
        ] = payload.value;
      } else {
        state.equipmentDetails.records[payload.tableIndexRow][
          payload.tableRowName
        ] = payload.value;
      }
    },

    /**
     * * ONREVERT TABLE EQUIPMENT DETAIL *
     * Todo: to change table on master data equipmentDetail page
     * @param action
     */
    onRevertTableEquipmentDetails: (state, action) => {
      const payload = action.payload;

      state.equipmentDetails.revert = state.equipmentDetails.records;
      if (!payload) state.equipmentDetails.onRevert = true;
      else state.equipmentDetails.onRevert = false;
    },

    /*
      EquipmentRom
    */
    /**
     * * ADD DATA TABLE EQUIPMENT ROM *
     * Todo: to add table on master data equipmentRom page
     * @param action
     */
    addDataTableEquipmentRom: (state, action) => {
      const payload = action.payload;

      state.equipmentRom?.records.unshift(payload);
    },

    /**
     * * REMOVE FIRST DATA TABLE EQUIPMENT ROM *
     * Todo: to remove first row on table master data equipmentRom page
     * @param action
     */
    removeFirstDataTableEquipmentRom: (state, action) => {
      state.equipmentRom?.records.shift();
    },

    /**
     * * REMOVE DATA TABLE EQUIPMENT ROM *
     * Todo: to remove row on table master data equipmentRom page
     * @param action
     */
    onRemoveDataTableEquipmentRom: (state, action) => {
      const payload = action.payload;

      state.equipmentRom.records.splice(payload.tableIndexRow, 1);
    },

    /**
     * * ONCHANGE TABLE EQUIPMENT ROM *
     * Todo: to change table on master data equipmentRom page
     * @param action
     */
    onChangeTableEquipmentRom: (state, action) => {
      const payload = action.payload;

      if (payload.tableRowName == "equipment.contractorName") {
        state.equipmentRom.records[payload.tableIndexRow]["equipment"]["type"] =
          "";
        state.equipmentRom.records[payload.tableIndexRow]["equipment"][
          "equipmentClass"
        ] = "";
        state.equipmentRom.records[payload.tableIndexRow]["equipment"][
          "contractorName"
        ] = payload.value;
        state.equipmentRom.records[payload.tableIndexRow]["contractor"] =
          payload.value;
      } else if (payload.tableRowName == "equipment.type") {
        state.equipmentRom.records[payload.tableIndexRow]["equipment"]["id"] =
          payload.id;
        state.equipmentRom.records[payload.tableIndexRow]["equipment"]["type"] =
          payload.value;
        state.equipmentRom.records[payload.tableIndexRow]["type"] =
          payload.value;
      } else if (payload.tableRowName == "equipment.equipmentClass") {
        state.equipmentRom.records[payload.tableIndexRow]["equipment"]["id"] =
          payload.id;
        state.equipmentRom.records[payload.tableIndexRow]["equipment"][
          "equipmentClass"
        ] = payload.value;
        state.equipmentRom.records[payload.tableIndexRow]["equipmentClass"] =
          payload.value;
      } else {
        state.equipmentRom.records[payload.tableIndexRow][
          payload.tableRowName
        ] = payload.value;
      }
    },

    /**
     * * ONREVERT TABLE EQUIPMENT ROM *
     * Todo: to change table on master data equipmentRom page
     * @param action
     */
    onRevertTableEquipmentRom: (state, action) => {
      const payload = action.payload;

      state.equipmentRom.revert = state.equipmentRom.records;
      if (!payload) state.equipmentRom.onRevert = true;
      else state.equipmentRom.onRevert = false;
    },

    /**
     * * ONREVERT TABLE EQUIPMENT ROM *
     * Todo: to change table on master data equipmentRom page
     * @param action
     */
    onRevertChangeTableEquipmentRom: (state, action) => {
      state.equipmentRom.records = state.equipmentRom.revert;

      state.equipmentRom.revert = [];
      state.equipmentRom.onRevert = false;
    },

    /*
      EquipmentHaul
    */
    /**
     * * ADD DATA TABLE EQUIPMENT ROM *
     * Todo: to add table on master data equipmentHaul page
     * @param action
     */
    addDataTableEquipmentHaul: (state, action) => {
      const payload = action.payload;

      state.equipmentHaul?.records.unshift(payload);
    },

    /**
     * * REMOVE FIRST DATA TABLE EQUIPMENT ROM *
     * Todo: to remove first row on table master data equipmentHaul page
     * @param action
     */
    removeFirstDataTableEquipmentHaul: (state, action) => {
      state.equipmentHaul?.records.shift();
    },

    /**
     * * REMOVE DATA TABLE EQUIPMENT ROM *
     * Todo: to remove row on table master data equipmentHaul page
     * @param action
     */
    onRemoveDataTableEquipmentHaul: (state, action) => {
      const payload = action.payload;

      state.equipmentHaul.records.splice(payload.tableIndexRow, 1);
    },

    /**
     * * ONCHANGE TABLE EQUIPMENT ROM *
     * Todo: to change table on master data equipmentHaul page
     * @param action
     */
    onChangeTableEquipmentHaul: (state, action) => {
      const payload = action.payload;

      if (payload.tableRowName == "equipment.contractorName") {
        state.equipmentHaul.records[payload.tableIndexRow]["equipment"][
          "type"
        ] = "";
        state.equipmentHaul.records[payload.tableIndexRow]["equipment"][
          "equipmentClass"
        ] = "";
        state.equipmentHaul.records[payload.tableIndexRow]["equipment"][
          "contractorName"
        ] = payload.value;
        state.equipmentHaul.records[payload.tableIndexRow]["contractor"] =
          payload.value;
      } else if (payload.tableRowName == "equipment.type") {
        state.equipmentHaul.records[payload.tableIndexRow]["equipment"]["id"] =
          payload.id;
        state.equipmentHaul.records[payload.tableIndexRow]["equipment"][
          "type"
        ] = payload.value;
        state.equipmentHaul.records[payload.tableIndexRow]["type"] =
          payload.value;
      } else if (payload.tableRowName == "equipment.equipmentClass") {
        state.equipmentHaul.records[payload.tableIndexRow]["equipment"]["id"] =
          payload.id;
        state.equipmentHaul.records[payload.tableIndexRow]["equipment"][
          "equipmentClass"
        ] = payload.value;
        state.equipmentHaul.records[payload.tableIndexRow]["equipmentClass"] =
          payload.value;
      } else {
        state.equipmentHaul.records[payload.tableIndexRow][
          payload.tableRowName
        ] = payload.value;
      }
    },

    /**
     * * ONREVERT TABLE EQUIPMENT ROM *
     * Todo: to change table on master data equipmentHaul page
     * @param action
     */
    onRevertTableEquipmentHaul: (state, action) => {
      const payload = action.payload;

      state.equipmentHaul.revert = state.equipmentHaul.records;
      if (!payload) state.equipmentHaul.onRevert = true;
      else state.equipmentHaul.onRevert = false;
    },

    /**
     * * ONREVERT TABLE EQUIPMENT ROM *
     * Todo: to change table on master data equipmentHaul page
     * @param action
     */
    onRevertChangeTableEquipmentHaul: (state, action) => {
      state.equipmentHaul.records = state.equipmentHaul.revert;

      state.equipmentHaul.revert = [];
      state.equipmentHaul.onRevert = false;
    },

    resetLocationList: (state) => {
      state.locationsByNames.locations = [];
    },

    resetLocation: (state) => {
      state.locations = {
        loading: false,
        records: [],
        revert: [],
        onRevert: false,
        error: "",
        recordsTotal: "",
        statusLogList: [],
        statusLogListTotal: 0,
        nameList: [],
        codeList: [],
      };
    },

    resetConfigurationContractor: (state) => {
      state.configurationContractor = {
        loading: false,
        records: [],
        revert: [],
        onRevert: false,
        error: "",
        recordsTotal: "",
        statusLogList: [],
        statusLogListTotal: 0,
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMasterDataAreas.pending, (state, action) => {
      state.areas.loading = true;
    });
    builder.addCase(getMasterDataAreas.fulfilled, (state, action) => {
      state.areas.loading = false;
      state.areas.records = action.payload.list;
      state.areas.recordsTotal = action.payload.recordsTotal;
    });
    builder.addCase(getMasterDataAreas.rejected, (state, action) => {
      state.areas.loading = false;
      state.areas.error = "Invalid get data";
    });

    // builder.addCase(getMasterDataAreaLogs.pending, (state, action) => {
    //   state.areas.loading = true;
    // });
    builder.addCase(getMasterDataAreaLogs.fulfilled, (state, action) => {
      state.areas.statusLogList = action.payload.list;
      state.areas.statusLogListTotal = action.payload.recordsTotal;
    });
    builder.addCase(getMasterDataAreaLogs.rejected, (state, action) => {
      state.areas.loading = false;
      state.areas.error = "Invalid get data";
    });

    builder.addCase(getMasterDataCrusher.pending, (state, action) => {
      state.crusher.loading = true;
    });
    builder.addCase(getMasterDataCrusher.fulfilled, (state, action) => {
      state.crusher.loading = false;
      state.crusher.records = action.payload.list;
      state.crusher.recordsTotal = action.payload.recordsTotal;
    });
    builder.addCase(getMasterDataCrusher.rejected, (state, action) => {
      state.crusher.loading = false;
      state.crusher.error = "Invalid get data";
    });

    builder.addCase(getMasterDataRomPort.pending, (state, action) => {
      state.RomPort.loading = true;
    });
    builder.addCase(getMasterDataRomPort.fulfilled, (state, action) => {
      state.RomPort.loading = false;
      state.RomPort.records = action.payload.list;
      state.RomPort.recordsTotal = action.payload.recordsTotal;
    });
    builder.addCase(getMasterDataRomPort.rejected, (state, action) => {
      state.RomPort.loading = false;
      state.RomPort.error = "Invalid get data";
    });

    builder.addCase(getMasterDataRomPortLogs.pending, (state, action) => {
      state.RomPort.loading = true;
    });
    builder.addCase(getMasterDataRomPortLogs.fulfilled, (state, action) => {
      state.RomPort.statusLogList = action.payload.list;
      state.RomPort.statusLogListTotal = action.payload.recordsTotal;
    });
    builder.addCase(getMasterDataRomPortLogs.rejected, (state, action) => {
      state.RomPort.loading = false;
      state.RomPort.error = "Invalid get data";
    });
    builder.addCase(getMasterDataCrusherLogs.pending, (state, action) => {
      state.crusher.loading = true;
    });
    builder.addCase(getMasterDataCrusherLogs.fulfilled, (state, action) => {
      state.crusher.statusLogList = action.payload.list;
      state.crusher.statusLogListTotal = action.payload.recordsTotal;
    });
    builder.addCase(getMasterDataCrusherLogs.rejected, (state, action) => {
      state.crusher.loading = false;
      state.crusher.error = "Invalid get data";
    });

    builder.addCase(getMasterDataJetty.pending, (state, action) => {
      state.jetty.loading = true;
    });
    builder.addCase(getMasterDataJetty.fulfilled, (state, action) => {
      state.jetty.loading = false;
      state.jetty.records = action.payload.list;
      state.jetty.recordsTotal = action.payload.recordsTotal;
    });
    builder.addCase(getMasterDataJetty.rejected, (state, action) => {
      state.jetty.loading = false;
      state.jetty.error = "Invalid get data";
    });

    builder.addCase(getMasterDataJettyconf.pending, (state, action) => {
      state.jettyconf.loading = true;
    });
    builder.addCase(getMasterDataJettyconf.fulfilled, (state, action) => {
      state.jettyconf.records = action.payload.list;
      state.jettyconf.recordsTotal = action.payload.recordsTotal;
    });
    builder.addCase(getMasterDataJettyconf.rejected, (state, action) => {
      state.jettyconf.loading = false;
      state.jettyconf.error = "Invalid get data";
    });

    builder.addCase(getMasterDataEquipmentHaulConf.pending, (state, action) => {
      state.equipmentHaulConf.loading = true;
    });
    builder.addCase(
      getMasterDataEquipmentHaulConf.fulfilled,
      (state, action) => {
        state.equipmentHaulConf.records = action.payload.list;
        state.equipmentHaulConf.recordsTotal = action.payload.recordsTotal;
      }
    );
    builder.addCase(
      getMasterDataEquipmentHaulConf.rejected,
      (state, action) => {
        state.equipmentHaulConf.loading = false;
        state.equipmentHaulConf.error = "Invalid get data";
      }
    );

    // builder.addCase(getMasterDataJettyLogs.pending, (state, action) => {
    //   state.jetty.loading = true;
    // });
    builder.addCase(getMasterDataJettyLogs.fulfilled, (state, action) => {
      state.jetty.statusLogList = action.payload.list;
      state.jetty.statusLogListTotal = action.payload.recordsTotal;
    });
    builder.addCase(getMasterDataJettyLogs.rejected, (state, action) => {
      state.jetty.loading = false;
      state.jetty.error = "Invalid get data";
    });

    builder.addCase(getMasterDataMaterial.pending, (state, action) => {
      state.materials.loading = true;
    });
    builder.addCase(getMasterDataMaterial.fulfilled, (state, action) => {
      state.materials.loading = false;
      state.materials.records = action.payload.list;
      state.materials.recordsTotal = action.payload.recordsTotal;
    });
    builder.addCase(getMasterDataMaterial.rejected, (state, action) => {
      state.materials.loading = false;
      state.materials.error = "Invalid get data";
    });

    // builder.addCase(getMasterDataMaterialLogs.pending, (state, action) => {
    //   state.materials.loading = true;
    // });
    builder.addCase(getMasterDataMaterialLogs.fulfilled, (state, action) => {
      state.materials.statusLogList = action.payload.list;
      state.materials.statusLogListTotal = action.payload.recordsTotal;
    });
    builder.addCase(getMasterDataMaterialLogs.rejected, (state, action) => {
      state.materials.loading = false;
      state.materials.error = "Invalid get data";
    });

    builder.addCase(getContractorCode.pending, (state, action) => {
      state.contractorsCode.loading = true;
    });
    builder.addCase(getContractorCode.fulfilled, (state, action) => {
      state.contractorsCode.loading = false;
      state.contractorsCode.records = action.payload.list;
      state.contractorsCode.recordsTotal = action.payload.recordsTotal;
    });
    builder.addCase(getContractorCode.rejected, (state, action) => {
      state.contractorsCode.loading = false;
      state.contractorsCode.error = "Invalid get data";
    });

    builder.addCase(getMasterDataContractors.pending, (state, action) => {
      state.contractors.loading = true;
    });
    builder.addCase(getMasterDataContractors.fulfilled, (state, action) => {
      state.contractors.loading = false;
      state.contractors.records = action.payload.list;
      state.contractors.recordsTotal = action.payload.recordsTotal;
    });
    builder.addCase(getMasterDataContractors.rejected, (state, action) => {
      state.contractors.loading = false;
      state.contractors.error = "Invalid get data";
    });

    // builder.addCase(getMasterDataContractorLogs.pending, (state, action) => {
    //   state.contractors.loading = true;
    // });
    builder.addCase(getMasterDataContractorLogs.fulfilled, (state, action) => {
      state.contractors.statusLogList = action.payload.list;
      state.contractors.statusLogListTotal = action.payload.recordsTotal;
    });
    builder.addCase(getMasterDataContractorLogs.rejected, (state, action) => {
      state.contractors.loading = false;
      state.contractors.error = "Invalid get data";
    });

    builder.addCase(getMasterDataLocations.pending, (state, action) => {
      state.locations.loading = true;
    });
    builder.addCase(getMasterDataLocations.fulfilled, (state, action) => {
      state.locations.records = action.payload;
    });
    builder.addCase(getMasterDataLocations.rejected, (state, action) => {
      state.locations.loading = false;
      state.locations.error = "Invalid get data";
    });

    builder.addCase(getAllMasterDataLoadingDumping.pending, (state, action) => {
      state.locationsAll.loading = true;
    });
    builder.addCase(
      getAllMasterDataLoadingDumping.fulfilled,
      (state, action) => {
        state.locationsAll.locations = action.payload;
      }
    );
    builder.addCase(
      getAllMasterDataLoadingDumping.rejected,
      (state, action) => {
        state.locationsAll.loading = false;
        state.locationsAll.error = "Invalid get data";
      }
    );

    builder.addCase(getMasterDataLoadingDumping.pending, (state, action) => {
      state.loadingDumping.loading = true;
    });
    builder.addCase(getMasterDataLoadingDumping.fulfilled, (state, action) => {
      state.loadingDumping.loading = false;
      state.loadingDumping.records = action.payload.list;
      state.loadingDumping.recordsTotal = action.payload.recordsTotal;
    });
    builder.addCase(getMasterDataLoadingDumping.rejected, (state, action) => {
      state.loadingDumping.loading = false;
      state.loadingDumping.error = "Invalid get data";
    });

    // builder.addCase(
    //   getMasterDataLoadingDumpingLogs.pending,
    //   (state, action) => {
    //     state.loadingDumping.loading = true;
    //   }
    // );
    builder.addCase(
      getMasterDataLoadingDumpingLogs.fulfilled,
      (state, action) => {
        state.loadingDumping.statusLogList = action.payload.list;
        state.loadingDumping.statusLogListTotal = action.payload.recordsTotal;
      }
    );
    builder.addCase(
      getMasterDataLoadingDumpingLogs.rejected,
      (state, action) => {
        state.loadingDumping.loading = false;
        state.loadingDumping.error = "Invalid get data";
      }
    );

    builder.addCase(
      getMasterDataConfigurationContractor.pending,
      (state, action) => {
        state.configurationContractor.loading = true;
      }
    );
    builder.addCase(
      getMasterDataConfigurationContractor.fulfilled,
      (state, action) => {
        state.configurationContractor.loading = false;
        state.configurationContractor.records = action.payload.list;
        state.configurationContractor.recordsTotal =
          action.payload.recordsTotal;
      }
    );
    builder.addCase(
      getMasterDataConfigurationContractor.rejected,
      (state, action) => {
        state.configurationContractor.loading = false;
        state.configurationContractor.error = "Invalid get data";
      }
    );

    builder.addCase(
      getMasterDataConfigurationContractorLogs.pending,
      (state, action) => {
        state.configurationContractor.loading = true;
      }
    );
    builder.addCase(
      getMasterDataConfigurationContractorLogs.fulfilled,
      (state, action) => {
        state.configurationContractor.statusLogList = action.payload.list;
        state.configurationContractor.statusLogListTotal =
          action.payload.recordsTotal;
      }
    );
    builder.addCase(
      getMasterDataConfigurationContractorLogs.rejected,
      (state, action) => {
        state.configurationContractor.loading = false;
        state.configurationContractor.error = "Invalid get data";
      }
    );

    builder.addCase(getMasterDataEquipments.pending, (state, action) => {
      state.equipments.loading = true;
    });
    builder.addCase(getMasterDataEquipments.fulfilled, (state, action) => {
      state.equipments.loading = false;
      state.equipments.records = action.payload.list;
      state.equipments.recordsTotal = action.payload.recordsTotal;
    });
    builder.addCase(getMasterDataEquipments.rejected, (state, action) => {
      state.equipments.loading = false;
      state.equipments.error = "Invalid get data";
    });

    // builder.addCase(getMasterDataEquipmentLogs.pending, (state, action) => {
    //   state.equipments.loading = true;
    // });
    builder.addCase(getMasterDataEquipmentLogs.fulfilled, (state, action) => {
      state.equipments.statusLogList = action.payload.list;
      state.equipments.statusLogListTotal = action.payload.recordsTotal;
    });
    builder.addCase(getMasterDataEquipmentLogs.rejected, (state, action) => {
      state.equipments.loading = false;
      state.equipments.error = "Invalid get data";
    });

    builder.addCase(getMasterDataEquipmentDetails.pending, (state, action) => {
      state.equipmentDetails.loading = true;
    });
    builder.addCase(
      getMasterDataEquipmentDetails.fulfilled,
      (state, action) => {
        state.equipmentDetails.loading = false;
        state.equipmentDetails.records = action.payload.list;
        state.equipmentDetails.recordsTotal = action.payload.recordsTotal;
      }
    );
    builder.addCase(getMasterDataEquipmentDetails.rejected, (state, action) => {
      state.equipmentDetails.loading = false;
      state.equipmentDetails.error = "Invalid get data";
    });

    builder.addCase(
      getMasterDataEquipmentDetailsSub.pending,
      (state, action) => {
        state.equipmentDetailsSub.loading = true;
      }
    );
    builder.addCase(
      getMasterDataEquipmentDetailsSub.fulfilled,
      (state, action) => {
        state.equipmentDetailsSub.loading = false;
        state.equipmentDetailsSub.records = action.payload.list;
        state.equipmentDetailsSub.recordsTotal = action.payload.recordsTotal;
      }
    );
    builder.addCase(
      getMasterDataEquipmentDetailsSub.rejected,
      (state, action) => {
        state.equipmentDetailsSub.loading = false;
        state.equipmentDetailsSub.error = "Invalid get data";
      }
    );

    // builder.addCase(
    //   getMasterDataEquipmentDetailsLogs.pending,
    //   (state, action) => {
    //     state.equipmentDetails.loading = true;
    //   }
    // );
    builder.addCase(
      getMasterDataEquipmentDetailsLogs.fulfilled,
      (state, action) => {
        state.equipmentDetails.statusLogList = action.payload.list;
        state.equipmentDetails.statusLogListTotal = action.payload.recordsTotal;
      }
    );
    builder.addCase(
      getMasterDataEquipmentDetailsLogs.rejected,
      (state, action) => {
        state.equipmentDetails.loading = false;
        state.equipmentDetails.error = "Invalid get data";
      }
    );

    builder.addCase(getMasterDataEquipmentRom.pending, (state, action) => {
      state.equipmentRom.loading = true;
    });
    builder.addCase(getMasterDataEquipmentRom.fulfilled, (state, action) => {
      state.equipmentRom.loading = false;
      state.equipmentRom.records = action.payload.list;
      state.equipmentRom.recordsTotal = action.payload.recordsTotal;
    });
    builder.addCase(getMasterDataEquipmentRom.rejected, (state, action) => {
      state.equipmentRom.loading = false;
      state.equipmentRom.error = "Invalid get data";
    });

    // builder.addCase(getMasterDataEquipmentRomLogs.pending, (state, action) => {
    //   state.equipmentRom.loading = true;
    // });
    builder.addCase(
      getMasterDataEquipmentRomLogs.fulfilled,
      (state, action) => {
        state.equipmentRom.statusLogList = action.payload.list;
        state.equipmentRom.statusLogListTotal = action.payload.recordsTotal;
      }
    );
    builder.addCase(getMasterDataEquipmentRomLogs.rejected, (state, action) => {
      state.equipmentRom.loading = false;
      state.equipmentRom.error = "Invalid get data";
    });

    builder.addCase(getMasterDataEquipmentHaul.pending, (state, action) => {
      state.equipmentHaul.loading = true;
    });
    builder.addCase(getMasterDataEquipmentHaul.fulfilled, (state, action) => {
      state.equipmentHaul.loading = false;
      state.equipmentHaul.records = action.payload.list;
      state.equipmentHaul.recordsTotal = action.payload.recordsTotal;
    });
    builder.addCase(getMasterDataEquipmentHaul.rejected, (state, action) => {
      state.equipmentHaul.loading = false;
      state.equipmentHaul.error = "Invalid get data";
    });

    // builder.addCase(getMasterDataEquipmentHaulLogs.pending, (state, action) => {
    //   state.equipmentHaul.loading = true;
    // });
    builder.addCase(
      getMasterDataEquipmentHaulLogs.fulfilled,
      (state, action) => {
        state.equipmentHaul.statusLogList = action.payload.list;
        state.equipmentHaul.statusLogListTotal = action.payload.recordsTotal;
      }
    );
    builder.addCase(
      getMasterDataEquipmentHaulLogs.rejected,
      (state, action) => {
        state.equipmentHaul.loading = false;
        state.equipmentHaul.error = "Invalid get data";
      }
    );

    builder.addCase(getMasterDataSublocations.pending, (state, action) => {
      state.subLocations.loading = true;
    });
    builder.addCase(getMasterDataSublocations.fulfilled, (state, action) => {
      state.subLocations.loading = false;
      state.subLocations.records = action.payload.list;
      state.subLocations.recordsTotal = action.payload.recordsTotal;
    });
    builder.addCase(getMasterDataSublocations.rejected, (state, action) => {
      state.subLocations.loading = false;
      state.subLocations.error = "Invalid get data";
    });

    builder.addCase(
      getMasterDataSublocationsDumping.pending,
      (state, action) => {
        state.subLocationsDumping.loading = true;
      }
    );
    builder.addCase(
      getMasterDataSublocationsDumping.fulfilled,
      (state, action) => {
        state.subLocationsDumping.records = action.payload.list;
        state.subLocationsDumping.recordsTotal = action.payload.recordsTotal;
      }
    );
    builder.addCase(
      getMasterDataSublocationsDumping.rejected,
      (state, action) => {
        state.subLocationsDumping.loading = false;
        state.subLocationsDumping.error = "Invalid get data";
      }
    );

    // builder.addCase(getMasterDataSublocationLogs.pending, (state, action) => {
    //   state.subLocations.loading = true;
    // });
    builder.addCase(getMasterDataSublocationLogs.fulfilled, (state, action) => {
      state.subLocations.statusLogList = action.payload.list;
      state.subLocations.statusLogListTotal = action.payload.recordsTotal;
    });
    builder.addCase(getMasterDataSublocationLogs.rejected, (state, action) => {
      state.subLocations.loading = false;
      state.subLocations.error = "Invalid get data";
    });

    builder.addCase(getMasterDataElevations.pending, (state, action) => {
      state.elevations.loading = true;
    });
    builder.addCase(getMasterDataElevations.fulfilled, (state, action) => {
      state.elevations.list = action.payload;
    });
    builder.addCase(getMasterDataElevations.rejected, (state, action) => {
      state.elevations.loading = false;
      state.elevations.error = "Invalid get data";
    });

    builder.addCase(
      getMasterDataLocationsByContractorName.pending,
      (state, action) => {
        state.locationsByNames.loading = true;
      }
    );
    builder.addCase(
      getMasterDataLocationsByContractorName.fulfilled,
      (state, action) => {
        state.locationsByNames.locations = action.payload;
      }
    );
    builder.addCase(
      getMasterDataLocationsByContractorName.rejected,
      (state, action) => {
        state.locationsByNames.loading = false;
        state.locationsByNames.error = "Invalid get data";
      }
    );

    builder.addCase(getOptionsContractorLocation.fulfilled, (state, action) => {
      state.listOptions = action.payload;
    });

    builder.addCase(getPitList.fulfilled, (state, action) => {
      state.pitList = action.payload;
    });
    builder.addCase(getMasterDataAreaName.pending, (state, action) => {
      state.areas.loading = true;
    });
    builder.addCase(getMasterDataAreaName.fulfilled, (state, action) => {
      state.areas.loading = false;
      state.areas.listNames = action.payload;
    });
    builder.addCase(getMasterDataAreaName.rejected, (state, action) => {
      state.areas.loading = false;
      state.areas.error = "Invalid get data";
    });

    builder.addCase(getMasterDataLocationName.pending, (state, action) => {
      state.locations.loading = true;
    });
    builder.addCase(getMasterDataLocationName.fulfilled, (state, action) => {
      state.locations.loading = false;
      state.locations.nameList = action.payload;
    });
    builder.addCase(getMasterDataLocationName.rejected, (state, action) => {
      state.locations.loading = false;
      state.locations.error = "Invalid get data";
    });

    builder.addCase(getMasterDataLocationCode.pending, (state, action) => {
      state.locations.loading = true;
    });
    builder.addCase(getMasterDataLocationCode.fulfilled, (state, action) => {
      state.locations.loading = false;
      state.locations.codeList = action.payload;
    });
    builder.addCase(getMasterDataLocationCode.rejected, (state, action) => {
      state.locations.loading = false;
      state.locations.error = "Invalid get data";
    });

    builder.addCase(getMasterDataSublocationName.pending, (state, action) => {
      state.subLocations.loading = true;
    });
    builder.addCase(getMasterDataSublocationName.fulfilled, (state, action) => {
      state.subLocations.loading = false;
      state.subLocations.nameList = action.payload;
    });
    builder.addCase(getMasterDataSublocationName.rejected, (state, action) => {
      state.subLocations.loading = false;
      state.subLocations.error = "Invalid get data";
    });

    builder.addCase(getMasterDataContractorName.pending, (state, action) => {
      state.contractors.loading = true;
    });
    builder.addCase(getMasterDataContractorName.fulfilled, (state, action) => {
      state.contractors.loading = false;
      state.contractors.nameList = action.payload;
    });
    builder.addCase(getMasterDataContractorName.rejected, (state, action) => {
      state.contractors.loading = false;
      state.contractors.error = "Invalid get data";
    });

    builder.addCase(getMasterDataContractorCode.pending, (state, action) => {
      state.contractors.loading = true;
    });
    builder.addCase(getMasterDataContractorCode.fulfilled, (state, action) => {
      state.contractors.loading = false;
      state.contractors.codeList = action.payload;
    });
    builder.addCase(getMasterDataContractorCode.rejected, (state, action) => {
      state.contractors.loading = false;
      state.contractors.error = "Invalid get data";
    });

    builder.addCase(getMasterDataEquipmentType.pending, (state, action) => {
      state.equipments.loading = true;
    });
    builder.addCase(getMasterDataEquipmentType.fulfilled, (state, action) => {
      state.equipments.loading = false;
      state.equipments.typeList = action.payload;
    });
    builder.addCase(getMasterDataEquipmentType.rejected, (state, action) => {
      state.equipments.loading = false;
      state.equipments.error = "Invalid get data";
    });

    builder.addCase(getMasterDataEquipmentClass.pending, (state, action) => {
      state.equipments.loading = true;
    });
    builder.addCase(getMasterDataEquipmentClass.fulfilled, (state, action) => {
      state.equipments.loading = false;
      state.equipments.classList = action.payload;
    });
    builder.addCase(getMasterDataEquipmentClass.rejected, (state, action) => {
      state.equipments.loading = false;
      state.equipments.error = "Invalid get data";
    });

    builder.addCase(getMasterDataEquipmentBrand.pending, (state, action) => {
      state.equipments.loading = true;
    });
    builder.addCase(getMasterDataEquipmentBrand.fulfilled, (state, action) => {
      state.equipments.loading = false;
      state.equipments.brandList = action.payload;
    });
    builder.addCase(getMasterDataEquipmentBrand.rejected, (state, action) => {
      state.equipments.loading = false;
      state.equipments.error = "Invalid get data";
    });

    builder.addCase(getMasterDataEquipmentRomJob.pending, (state, action) => {
      state.equipmentRom.loading = true;
    });
    builder.addCase(getMasterDataEquipmentRomJob.fulfilled, (state, action) => {
      state.equipmentRom.loading = false;
      state.equipmentRom.jobList = action.payload;
    });
    builder.addCase(getMasterDataEquipmentRomJob.rejected, (state, action) => {
      state.equipmentRom.loading = false;
      state.equipmentRom.error = "Invalid get data";
    });

    builder.addCase(getMasterDataEquipmentHaulAxle.pending, (state, action) => {
      state.equipmentHaul.loading = true;
    });
    builder.addCase(
      getMasterDataEquipmentHaulAxle.fulfilled,
      (state, action) => {
        state.equipmentHaul.loading = false;
        state.equipmentHaul.axleList = action.payload;
      }
    );
    builder.addCase(
      getMasterDataEquipmentHaulAxle.rejected,
      (state, action) => {
        state.equipmentHaul.loading = false;
        state.equipmentHaul.error = "Invalid get data";
      }
    );

    builder.addCase(
      getMasterDataCrusherDesignCapacity.pending,
      (state, action) => {
        state.crusher.loading = true;
      }
    );
    builder.addCase(
      getMasterDataCrusherDesignCapacity.fulfilled,
      (state, action) => {
        state.crusher.loading = false;
        state.crusher.designCapacityList = action.payload;
      }
    );
    builder.addCase(
      getMasterDataCrusherDesignCapacity.rejected,
      (state, action) => {
        state.crusher.loading = false;
        state.crusher.error = "Invalid get data";
      }
    );

    builder.addCase(
      getMasterDataRomPortDesignCapacity.pending,
      (state, action) => {
        state.RomPort.loading = true;
      }
    );
    builder.addCase(
      getMasterDataRomPortDesignCapacity.fulfilled,
      (state, action) => {
        state.RomPort.loading = false;
        state.RomPort.designCapacityList = action.payload;
      }
    );
    builder.addCase(
      getMasterDataRomPortDesignCapacity.rejected,
      (state, action) => {
        state.RomPort.loading = false;
        state.RomPort.error = "Invalid get data";
      }
    );

    builder.addCase(
      getMasterDataJettyDesignCapacity.pending,
      (state, action) => {
        state.jetty.loading = true;
      }
    );
    builder.addCase(
      getMasterDataJettyDesignCapacity.fulfilled,
      (state, action) => {
        state.jetty.loading = false;
        state.jetty.designCapacityList = action.payload;
      }
    );
    builder.addCase(
      getMasterDataJettyDesignCapacity.rejected,
      (state, action) => {
        state.jetty.loading = false;
        state.jetty.error = "Invalid get data";
      }
    );

    builder.addCase(
      getMasterDataLocationByContractorName.pending,
      (state, action) => {
        state.locations.loading = true;
      }
    );
    builder.addCase(
      getMasterDataLocationByContractorName.fulfilled,
      (state, action) => {
        state.locations.records = action.payload;
        state.locations.loading = false;
      }
    );
    builder.addCase(
      getMasterDataLocationByContractorName.rejected,
      (state, action) => {
        state.locations.loading = false;
      }
    );

    builder.addCase(fetchContractorByArea.pending, (state, action) => {
      state.contractorByArea.loading = true;
    });
    builder.addCase(fetchContractorByArea.fulfilled, (state, action) => {
      state.contractorByArea.loading = false;
      state.contractorByArea.records = action.payload;
    });
    builder.addCase(fetchContractorByArea.rejected, (state, action) => {
      state.contractorByArea.loading = false;
      state.contractorByArea.error = action.error;
    });
  },
});

export const masterDataAreasSelector = (state) => state.masterData.areas;
export const masterDataConfigurationContractorSelector = (state) =>
  state.masterData.configurationContractor;
export const masterDataMaterialsSelector = (state) =>
  state.masterData.materials;
export const masterDataContractorsSelector = (state) =>
  state.masterData.contractors;
export const masterDataProductionStatusSelector = (state) =>
  state.masterData.productionStatus;
export const masterDataSubLocationsSelector = (state) =>
  state.masterData.subLocations;
export const masterDataSubLocationsDumpingSelector = (state) =>
  state.masterData.subLocationsDumping;
export const masterDataElevationsSelector = (state) =>
  state.masterData.elevations;
export const masterDataLocationsSelector = (state) =>
  state.masterData.locations;
export const masterDataLocationsAllSelector = (state) =>
  state.masterData.locationsAll;
export const masterDataEquipmentsSelector = (state) =>
  state.masterData.equipments;
export const masterDataEquipmentRomSelector = (state) =>
  state.masterData.equipmentRom;
export const masterDataEquipmentHaulSelector = (state) =>
  state.masterData.equipmentHaul;
export const masterDataEquipmentDetailsSelector = (state) =>
  state.masterData.equipmentDetails;
export const masterDataEquipmentDetailsSubSelector = (state) =>
  state.masterData.equipmentDetailsSub;
export const masterDataLoadingDumpingSelector = (state) =>
  state.masterData.loadingDumping;
export const masterDataCrusherSelector = (state) => state.masterData.crusher;
export const masterDataRomPortSelector = (state) => state.masterData.RomPort;
export const masterDataJettySelector = (state) => state.masterData.jetty;
export const masterDataJettyConfSelector = (state) =>
  state.masterData.jettyconf;
export const masterDataEwhSelector = (state) =>
  state.masterData.equipmentHaulConf;
export const masterDataOptions = (state) => state.masterData.listOptions;
export const pitListSelector = (state) => state.masterData.pitList;
export const masterDataLocationsByContractorNameSelector = (state) =>
  state.masterData.locationsByNames;
export const contractorCodeSelector = (state) =>
  state.masterData.contractorsCode;
export const contractorByArea = (state) =>
  state.masterData.contractorByArea;

export const {
  onChangeTableConfigurationContractor,
  addDataTableConfigurationContractor,
  removeFirstDataTableConfigurationContractor,
  onRevertChangeTableConfigurationContractor,
  onRevertTableConfigurationContractor,
  onRemoveDataTableConfigurationContractor,
  onChangeTableArea,
  addDataTableArea,
  onRevertTableArea,
  onRemoveDataTableArea,
  onChangeTableCrusher,
  onChangeTableRomPort,
  addDataTableCrusher,
  addDataTableRomPort,
  removeFirstDataTableCrusher,
  onRevertChangeTableCrusher,
  onRevertTableCrusher,
  onRevertTableRomPort,
  onRemoveDataTableCrusher,
  onRemoveDataTableRomPort,
  onChangeTableJetty,
  addDataTableJetty,
  onChangeTableInput,
  onChangeEwhConfInput,
  removeFirstDataTableJetty,
  onRevertChangeTableJetty,
  onRevertTableJetty,
  onRemoveDataTableJetty,
  onChangeTableLocation,
  addDataTableLocation,
  onRevertTableLocation,
  onRemoveDataTableLocation,
  onChangeTableContractor,
  addDataTableContractor,
  onRevertTableContractor,
  onRemoveDataTableContractor,
  onChangeTableSubLocation,
  addDataTableSubLocation,
  onRevertTableSubLocation,
  onRemoveDataTableSubLocation,
  onChangeTableEquipment,
  addDataTableEquipment,
  onRevertTableEquipment,
  onRemoveDataTableEquipment,
  onChangeTableMaterial,
  addDataTableMaterial,
  onRevertTableMaterial,
  onRemoveDataTableMaterial,
  onChangeTableEquipmentDetails,
  addDataTableEquipmentDetails,
  onRevertTableEquipmentDetails,
  onRemoveDataTableEquipmentDetails,
  onChangeTableEquipmentRom,
  addDataTableEquipmentRom,
  removeFirstDataTableEquipmentRom,
  onRevertChangeTableEquipmentRom,
  onRevertTableEquipmentRom,
  onRemoveDataTableEquipmentRom,
  onChangeTableEquipmentHaul,
  addDataTableEquipmentHaul,
  removeFirstDataTableEquipmentHaul,
  onRevertChangeTableEquipmentHaul,
  onRevertTableEquipmentHaul,
  onRemoveDataTableEquipmentHaul,
  resetLocationList,
  resetLocation,
  resetConfigurationContractor
} = masterDataSlice.actions;

export default masterDataSlice.reducer;
